.ro-service-1-wrapper {
    position: relative;
}

.ro-service-1-item {
    position: relative;
    margin-bottom: 100px;

    .ro-image {
        position: relative;
        width: 60%;
        z-index: 2;
    }

    .ro-content {
        position: relative;
        width: 50%;
        z-index: 3;

        p {
            text-align: center;
            margin-bottom: 0;
            padding: 30px 30px 50px;

            .ro-more{
                position: absolute;right:30px;bottom:10px;
            }
        }
    }

    > :first-child {
        float: left;
        margin-right: -10%;
    }

    > :nth-child(2) {
        float: right;

        img {
            float: right;
        }
    }
}

@include breakpoint-min($screen-lg) {
    .ro-service-1-item.ro-service-1-v {
        padding-top: 150px;

        .ro-image {
            position: relative;
            z-index: 2;
            margin-bottom: -70px;
            width: 85%;
        }

        .ro-content {
            position: relative;
            width: 100%;
            z-index: 3;

            h3, p {
                float: left;
            }

            h3 {
                width: 30%;
                padding-top: 80px;
            }

            p {
                width: 70%;
                text-align: center;
                margin-bottom: 0;
                padding: 30px;
            }
        }
    }
}


@include breakpoint-max($screen-sm) {
    .ro-service-1-item {
        margin-bottom: 50px;
        .ro-image {
            position: relative;
            width: 80%;
            z-index: 2;
        }

        .ro-content {
            position: relative;
            width: 70%;
            z-index: 3;

            p {
                text-align: center;
                margin-bottom: 0;
                padding: 30px;
            }
        }

        > :first-child {
            margin-right: -50%;
        }
    }
}


@include breakpoint-max($screen-xs) {
    .ro-service-1-item {
        .ro-image {
            display: none;
        }

        .ro-content {
            width: 100%;
        }

        > :first-child {
            float: left;
            margin-right: -100%;
        }
    }
}


.ro-service-item-2 {
    @include center-block;
    max-width: 370px;
    text-align: center;
    margin-bottom: 25px;

    img {
        margin-bottom: 30px;
    }

    h4 {
        margin-bottom: 15px;

        &:after {
            @include center-block;
            @include easing-animation;
            margin-top: 10px;
            content: " ";
            height: 0;
            width: 0;
            border-top: 1px solid $dark-color;
        }
    }

    a:hover & {
        h4:after {
            width: 30px;
        }
    }
}

.ro-service-item-3 {
    @include easing-animation;
    @include center-block;
    max-width: 370px;
    margin-top: 10px;
    margin-bottom: 70px;
    text-align: center;

    h3 {
        @include easing-animation;
        font-weight: 600;
        margin-top: 40px;
        color: $light-color;

        &:after {
            @include center-block;
            @include easing-animation;
            margin-top: 10px;
            content: " ";
            height: 0;
            width: 0;
            border-top: 1px solid $light-color;
        }
    }

    p {
        @include easing-animation;
        margin-bottom: 10px;
        padding: 15px;
        background-color: rgba($light-color, 0.6);
    }

    a:hover & {
        margin-top: 0;
        margin-bottom: 70px - 10px;

        h3 {
            margin-bottom: 35px;
            &:after {
                width: 30px;
            }
        }

        p {
            margin-bottom: 20px;
        }
    }
}

.ro-service-fullwidth {
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: 0 0;
    padding-bottom: 40px;

    &.ro-bg-right {
        background-position: 100% 0;
    }

    h3 {
        margin-bottom: 50px;
        margin-top: 60px;
    }
}

.ro-service-list ul {
    padding: 40px (40px + 50px) 40px 40px;

    li {
        @include clearfix;
        position: relative;
        line-height: 40px;
        font-style: italic;
    }

    .ro-service {
        position: relative;
        float: left;
        padding-right: 30px;
    }

    .ro-separator {
        position: relative;
        display: table-cell;
        content: " ";
        width: 2000px;
        height: 28px;
        border-bottom: 1px solid $light-color;
    }

    .ro-price {
        text-align: right;
        position: absolute;
        font-weight: 700;
        width: 50px;
        right: -50px;
        top: 0;
    }
}

@include breakpoint-in($screen-xs, $screen-md) {
    .ro-service-fullwidth, .ro-service-fullwidth.ro-bg-right {
        background-position: 50% 0;
    }
}


@include breakpoint-max($screen-xs) {
    .ro-service-list ul {
        padding: 15px (15px + 40px) 15px 15px;

        .ro-service {
            padding-right: 0;
        }

        .ro-price {
            width: 40px;
            right: -40px;
        }

        .ro-separator {
            display: none;
        }
    }
}


.ro-specialize {
    padding-top: 60px;
    padding-bottom: 60px;

    img {
        display: inline-block;
        margin-bottom: 30px;
    }

    h3 {
        margin-bottom: 35px;
    }

    .ro-specialize-content {
        background-color: rgba($light-color, 0.8);
        padding-top: 40px;
        padding-bottom: 40px;
        padding-left: 60px;
        padding-right: 40px;

        li {
            list-style: disc;
            padding: 10px 0;
        }
    }
}

@include breakpoint-max($screen-xs) {
    .ro-specialize {
        .ro-specialize-content {
            padding-top: 15px;
            padding-bottom: 15px;
            padding-left: 35px;
            padding-right: 15px;
        }
    }
}


.ro-service-accordion {
    .ro-service-list ul {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .panel {
        border: 0;
        margin-bottom: 30px;
    }

    .panel-heading {
        padding: 0;
        border: 1px solid $light-color;

        a {
            padding: 8px 30px;
            display: block;
            background-color: lighten($bg-color-4, 8%);

            &:before {
                position: relative;
                left: -20px;
                content: "\e177";
                font-family: "aqua icon" !important;
                display: inline-block;
                font-size: 24px;
                vertical-align: -20%;
            }

            &[aria-expanded='true'] {
                &:before {
                    content: "\e170";
                }
                background-color: $light-color;
            }
        }
    }

    .panel-body {
        border-top: 0 !important;
        background-color: lighten($bg-color-4, 8%);
        height: 200px;
        overflow: auto;
    }
}