////////////////////////////////////ANIMATION///////////////////////////////////

@mixin easing-animation {
    transition: all $duration-time;
}

@mixin easing-animation-long {
    transition: all $duration-longtime;
}

@mixin hover($color, $bg, $border) {
    @include easing-animation;

    &:hover, &:focus {
        @if $color {
            color: $color;
        }

        @if $bg {
            background-color: $bg;
        }

        @if $border {
            border-color: $border;
        }
    }
}

@mixin hover-color($thiscolor) {
    @include easing-animation;

    &:hover, &:focus {
        color: $thiscolor;
    }
}

@mixin hover-bg($thiscolor) {
    @include easing-animation;

    &:hover, &:focus {
        background: $thiscolor;
    }
}

@mixin hover-border($thiscolor) {
    @include easing-animation;

    &:hover, &:focus {
        border: $thiscolor;
    }
}

@mixin btn-process-h($bgc) {
    z-index: 1;

    &:before {
        @include easing-animation;
        content: " ";
        position: absolute;
        left: 0;
        top: 0;
        width: 0;
        height: 100%;
        background-color: $bgc;
        z-index: -1;
    }

    &:hover:before {
        width: 100%;
    }
}

@mixin all-class($class) {
    [class^="#{$class}"],

    [class*="#{$class}"] {
        @content;
    }
}

@mixin special-zoom-in {
    animation: SpecialZoomIn 8s forwards;
    transform-origin: 30% 0%;
}

@mixin special-zoom-out {
    animation: SpecialZoomOut 8s forwards;
    transform-origin: 30% 0%;
}

//////////////////////////////////////SCREEN/////////////////////////////////////////

@mixin breakpoint-min($point) {
    @media (min-width: #{$point}) {
        @content;
    }
}

@mixin breakpoint-max($point) {
    @media (max-width: #{$point - 1}) {
        @content;
    }
}

@mixin breakpoint-in($point1, $point2) {
    @media (min-width: #{$point1}) and (max-width: #{$point2 - 1}) {
        @content;
    }
}

@mixin breakpoint-hmin($point) {
    @media (min-height: #{$point}) {
        @content;
    }
}

@mixin breakpoint-hmax($point) {
    @media (max-height: #{$point - 1}) {
        @content;
    }
}

@mixin breakpoint-hin($point1, $point2) {
    @media (min-height: #{$point1}) and (max-height: #{$point2 - 1}) {
        @content;
    }
}

//////////////////////////////////////DIV/////////////////////////////////////////

@mixin size($width, $height) {
    width: $width;
    height: $height;
}

@mixin square($size) {
    @include size($size, $size);
}

@mixin clearfix {
    &:before,
    &:after {
        content: " ";
        // 1
        display: table;
        // 2
   
    }

    &:after {
        clear: both;
    }
}

@mixin overlay($z-index) {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: $z-index;
}

@mixin layer-before-on-bg($after-bg: rgba(0, 0, 0, 0.6)) {
    position: relative;

    &:before {
        content: " ";
        position: absolute;
        display: block;
        background: $after-bg;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
}

@mixin layer-after-on-bg($after-bg: rgba(0, 0, 0, 0.6)) {
    position: relative;
    z-index: 1;

    &:after {
        content: " ";
        position: absolute;
        display: block;
        background: $after-bg;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;
    }
}

@mixin nopadding {
    padding: 0;
}

@mixin col-nopadding {
    padding-left: 0;
    padding-right: 0;
}

@mixin center-block {
    display: block;
    margin-right: auto;
    margin-left: auto;
}

@mixin middle {
    position: relative;
    display: block;
    top: 50%;
    transform: translateY(-50%);
}

@mixin center {
    @include middle;
    @include center-block;
}

@mixin bottom {
    position: relative;
    display: block;
    top: 100%;
    transform: translateY(-100%);
}

@mixin ab-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include clearfix;
}

@mixin ab-bottom {
    position: absolute;
    top: 100%;
    transform: translateY(-100%);
    @include clearfix;
}