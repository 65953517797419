.ro-underconstruction-wrapper {
    @include layer-after-on-bg(rgba($light-color, 0.9));
    background-image: url("../images/reservation.jpg");
    background-size: cover;
    border: 1px solid $gray-color;
    text-align: center;
    padding-top: 100px;
    padding-bottom: 100px;
}

.ro-countdown-clock.is-countdown {
    .countdown-section {
        display: inline-block;

        .countdown-amount, .countdown-period {
            display: block;
            margin: 10px;
            text-align: center;
            font-weight: 600;
        }

        .countdown-amount {
            width: 80px;
            height: 80px;
            background-color: $main-color;
            color: $light-color;
            line-height: 74px;
            font-size: 45px;
        }

        .countdown-period {
            font-size: 18px;
        }
    }
}

.ro-404-container {
    @include center-block;
    position: relative;
    background-image: url("../images/404.jpg");
    background-size: cover;
    max-width: 1520px;
    min-height: 61vh;
    height: 400px;

    .ro-heading {
        font-size: 72px;
        margin-bottom: 0;
        line-height: 1.2;
    }
}

.ro-demo-select-header-container {
    position: relative;
    padding-top: 30px;
    height: 380px;
    margin-bottom: 100px;
    text-align: center;
    color: $light-color;

    .ro-brand {
        margin: 25px 0;
        width: 200px;
    }
}

.ro-demo-select-footer-container {
    padding-top: 100px;
    padding-bottom: 100px;
    background-image: url("../images/demoselect-footer.png");
    background-repeat: no-repeat;
    background-position: 100% 50%;
    background-size: auto 100%;
}

.ro-demo-item {
    @include center-block;
    text-align: center;
    max-width: 360px;
    margin-bottom: 50px;

    .ro-content {
        position: relative;
        background-color: $lightgray-color;
        border: 1px solid $gray-color;
        padding: 10px;
        margin-bottom: 20px;
    }

    img {
        width: 100%;
        @include easing-animation;
    }

    .ro-overlay {
        @include easing-animation;
        padding: 10px;
        opacity: 0;

        .ro-cell-vertical-wrapper {
            background-color: rgba($bg-color-4, 0.7);
        }

        &.ro-comming {
            opacity: 1;

            .ro-cell-vertical-wrapper {
                background-color: transparent;
            }
        }
    }

    &:hover {
        .ro-overlay {
            opacity: 1;
        }
    }
}

@include breakpoint-max($screen-lg) {
    .ro-demo-select-header-container {
        margin-bottom: 20px;
    }
}