.ro-testimonial-slider {
    .ro-item {
        text-align: center;

        h5 {
            margin-bottom: 20px;
        }
    }

    .flex-direction-nav {
        position: relative;
        text-align: center;
        @include clearfix;

        .flex-nav-next, .flex-nav-prev {
            display: inline-block;
            margin: 0 15px;
        }

        .flex-next, .flex-prev {
            font-size: 0;

            &:before {
                font-size: 16px;
                font-family: "aqua icon" !important;
            }
        }

        .flex-next:before {
            content: "d";
        }

        .flex-prev:before {
            content: "b";
        }
    }
}

.ro-testimonial-scroll-wrapper {
    position: relative;
    float: left;
    &:before {
        content: " ";
        z-index: 512;
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        height: 80px;
        width: 100%;
        background: linear-gradient(to bottom, rgba(243, 243, 240, 1) 0%, rgba(243, 243, 240, 0.99) 1%, rgba(243, 243, 240, 0.5) 50%, rgba(243, 243, 240, 0) 100%);
    }

    &:after {
        content: " ";
        z-index: 512;
        position: absolute;
        display: block;
        bottom: 0;
        left: 0;
        height: 80px;
        width: 100%;
        background: linear-gradient(to bottom, rgba(243, 243, 240, 0) 0%, rgba(243, 243, 240, 0.5) 50%, rgba(243, 243, 240, 0.99) 99%, rgba(243, 243, 240, 1) 100%);
    }
}

.ro-testimonial-scroll {
    position: relative;
    float: left;
    text-align: center;
    background-color: rgba($light-color, 0.5);
    height: 460px;
    overflow: auto;

    .ro-hr {
        border-color: $gray-color;
        margin: 0 auto;
        width:75%;
    }

    .ro-item {
        padding: 50px 40px 20px;
    }
}

.ro-testimonial-scroll-image{
    height:460px;
    padding-top: 60px;
}