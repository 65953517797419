.ro-membership-item {
    @include center-block;
    max-width: 270px;
    padding-top: 15px;
    padding-bottom: 50px;
    margin-bottom: 30px;
    text-align: center;
    border: 1px solid $gray-color;

    .ro-price {
        position: relative;
        width: 100%;

        img {
            width: 100%;
        }

        .ro-overlay {
            @include easing-animation;
            padding: 10px;
            opacity: 0;
            transform: scale(0.1);

            .ro-cell-vertical-wrapper {
                background-color: rgba($light-color, 0.8);
            }
        }

        h1 {
            margin-bottom: 12px;
        }

        .ro-small {
            font-size: 50%;
        }
    }

    .ro-option {
        padding: 30px;
        padding-top: 40px;

        li:not(:first-child) {
            line-height: 50px;
            border-top: 1px solid $gray-color;
        }
    }

    &:hover {
        .ro-price .ro-overlay {
            opacity: 1;
            transform: scale(1);
        }

        > .ro-btn-bd-1 {
            background-color: $main-color;
            color: $light-color;
            border-color: $main-color;
        }
    }
}

@include breakpoint-in($screen-sm, $screen-md) {
    .ro-membership-wrapper {
        & > div:nth-child(2n) {
            .ro-membership-item {
                margin-left: 0;
            }
        }

        & > div:nth-child(2n + 1) {
            .ro-membership-item {
                margin-right: 0;
            }
        }
    }
}