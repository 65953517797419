.ro-color-main {
    color: $main-color;
}

.ro-color-light {
    color: $light-color;
}

.ro-color-dark {
    color: $dark-color;
}

.ro-bg-dark {
    background-color: darken($dark-color, 10%);
}

.ro-bg-light {
    background-color: $light-color;
}

.ro-bg-trans-light {
    background-color: rgba($light-color,0.7)
}

.ro-bg-main {
    background-color: $main-color;
}

.ro-bgc-1 {
    background-color: $bg-color-1;
}

.ro-bgc-2 {
    background-color: $bg-color-2;
}

.ro-bgc-3 {
    background-color: $bg-color-3;
}

.ro-bgc-4 {
    background-color: $bg-color-4;
}

.ro-bgc-5 {
    background-color: $bg-color-5;
}

.ro-bgc-trans-1 {
    background-color: rgba($bg-color-1, 0.7);
}

.ro-bgc-trans-2 {
    background-color: rgba($bg-color-2, 0.7);
}

.ro-bgc-trans-3 {
    background-color: rgba($bg-color-3, 0.7);
}

.ro-bgc-trans-4 {
    background-color: rgba($bg-color-4, 0.7);
}

.ro-bgc-trans-5 {
    background-color: rgba($bg-color-5, 0.7);
}