.ro-main-footer {
    padding-top: 60px;
    padding-bottom: 30px;
    border-top: 1px solid $gray-color;

    .ro-open-time {
        p {
            margin-bottom: 15px;
        }
    }

    .ro-footer-separator {
        @include center-block;
        height: 220px;
        width: 0;
        border-left: 1px solid $gray-color;
    }

    .ro-footer-item {
        margin-bottom: 50px;
    }

    .ro-footer-newletter {
        position: relative;
        margin-bottom: 30px;

        a {
            @include easing-animation;
            position: absolute;
            display: block;
            height: 40px;
            width: 40px;
            line-height: 40px;
            text-align: center;
            top: 0;
            right: 0;
            background-color: $dark-color;
            color: $light-color;

            &:hover {
                background-color: $main-color;
            }
        }
    }

    .ro-social {
        text-align: right;

        li {
            margin-right: 5px;
            margin-left: 0;
        }
    }

    .ro-bg-dark & {
        color: $gray-color;
        border-top: 0;

        h5 {
            color: $light-color;
        }

        .ro-btn-bd-1 {
            color: $light-color;

            &:hover {
                background-color: $dark-color;
                border-color: $light-color;
            }
        }

        .ro-footer-newletter {
            input {
                border-color: $light-color;
            }

            a:hover {
                background-color: $gray-color;
            }
        }

        .ro-social {
            color: $darkgray-color;

            li a:hover {
                color: $light-color;
            }
        }
    }
}

.ro-foot-footer {
    text-align: center;
    border-top: 1px solid $gray-color;
    padding: 30px 0;

    h6 {
        margin-bottom: 10px;
        line-height: 1.6;
    }

    &.ro-index {
        padding: 20px 0;
        border: 0;
    }

    .ro-bg-dark & {
        color: $gray-color;
    }
}

@include breakpoint-max($screen-lg) {
    .ro-main-footer {
        text-align: center;

        .ro-social {
            text-align: center;

            li {
                margin: 0 2px;
            }
        }
    }
}