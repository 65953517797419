.ro-section {
    position: relative;
    display: block;
    width: 100%;
    float: left;

    .ro-section-mb {
        margin-bottom: 50px;
    }

    .ro-section-mb-small {
        margin-bottom: 30px;
    }

    .ro-section-mb-big {
        margin-bottom: 80px;
    }

    .ro-section-heading {
        margin-bottom: 40px;
    }

    &.ro-padding-top {
        padding-top: 50px;
    }

    &.ro-padding-top-big {
        padding-top: 80px;
    }

    &.ro-margin-bottom {
        margin-bottom: 80px;
    }
}