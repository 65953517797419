$main-nav-width: 270px;

.ro-hamburger {
    @include easing-animation;
    top: 25px;
    display: block;
    position: relative;
    background: transparent;
    border: 0;
    width: 22px;
    height: 14px;
    padding: 0;
    outline: none;
    cursor: pointer;
    z-index: 999;

    &::before,
    &::after,
    & span {
        @include easing-animation;
        background: $dark-color;
    }

    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 2px;
        width: 100%;
        left: 0;
        top: 50%;
        transform-origin: 50% 50%;
    }

    & span {
        position: absolute;
        width: 100%;
        height: 2px;
        left: 0;
        top: 50%;
        overflow: hidden;
        text-indent: 200%;
    }

    &::before {
        transform: translate3d(0, -7px, 0);
    }

    &::after {
        transform: translate3d(0, 7px, 0);
    }

    .ro-main-nav-opened & {
        position: fixed;
        right: 15px;

        & span {
            opacity: 0;
        }

        &::before {
            transform: rotate3d(0, 0, 1, 45deg);
        }

        &::after {
            transform: rotate3d(0, 0, 1, -45deg);
        }
    }
}

#ro-backtop {
    @include hover($light-color, $main-color, $main-color);
    @include square(40px);
    border: 1px solid $dark-color;
    position: fixed;
    display: none;
    bottom: 50px;
    right: 30px;
    background-color: $light-color;
    z-index: 992;
    text-align: center;
    font-size: 24px;
    line-height: 38px;
    text-align: center;
    outline: none;

    &.ro-show {
        display: block;
        animation: bounceInUp $duration-longtime both;
    }
}

/* nav style 1 */
.ro-main-nav-style-1 {
    position: relative;
    padding-top: 50px;
    text-align: center;

    .ro-brand {
        position: relative;
        display: inline-block;
        margin-bottom: 5px;

        img {
            @include center-block;
            margin-bottom: 20px;
        }
    }
}

.ro-main-nav-style-2 {
    position: relative;
    padding: 50px 0;

    .ro-brand {
        display: inline-block;
        height: 85px;
    }
}

.ro-main-nav-style-3 {
    position: absolute;
    width: 100%;
    top: 40px;
    z-index: 998;

    .ro-brand {
        width: 100%;
    }

    body:not(.ro-main-nav-opened) & .ro-hamburger {
        span, &:after, &:before {
            background-color: $light-color;
        }
    }
}

.ro-main-nav-shop {
    text-align: center;

    .ro-brand {
        border: 2px solid $dark-color;
        display: inline-block;
        padding: 10px 15px;
        height: 80px;
        margin: 50px 0;

        img {
            height: 100%;
        }
    }

    .ro-cart {
        position: absolute;
        top: -40px;
        font-size: 24px;
        right: 15px;

        &:before {
            @include square(20px);
            position: absolute;
            display: block;
            content: attr(data-content);
            font-size: $font-size-6;
            line-height: 20px;
            top: 0;
            right: -12px;
            border-radius: 10px;
            background-color: $main-color;
            color: $light-color;
        }
    }

    .ro-nav-content-wrapper {
        position: relative;
        border-top: 1px solid $gray-color;
        border-bottom: 1px solid $gray-color;
    }

    .ro-nav,
    .ro-hotline,
    .ro-option {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        line-height: 50px;

        i {
            font-size: 8px;
            margin-left: 5px;
        }
    }

    .ro-nav {
        li {
            font-family: "aqua",sans-serif;
            font-weight: 400;
            font-size: 14px;
            display: inline-block;
            margin: 0 10px;
            padding: 12px 0;
            line-height: 26px;

            a {
                position: relative;

                &:after {
                    @include easing-animation;
                    @include center-block;
                    content: " ";
                    height: 0;
                    width: 0;
                    border-top: 1px solid $dark-color;
                }

                &:hover:after {
                    width: 100%;
                }
            }
        }
    }

    .dropdown-toggle:hover {
        color: $main-color;
    }

    .btn, .dropdown-menu > li > a {
        font-weight: inherit;
    }

    .dropdown-menu, .btn-group.open .dropdown-toggle {
        box-shadow: none;
    }

    .btn-group.open .dropdown-toggle {
        background-color: $main-color;
        color: $light-color;
    }
}

@include breakpoint-min($screen-sm) {
    .ro-nav-content {
        position: relative;
        display: table;
        width: 100%;
        font-family: 'aqua', sans-serif;
        font-weight: 400;
        font-size: 14px;
        padding: 0 30px;

        > li {
            position: relative;
            display: table-cell;
            width: 300px;

            > a {
                display: inline-block;
                position: relative;
                padding: 15px 0;

                &:after {
                    @include easing-animation;
                    @include center-block;
                    content: " ";
                    height: 0;
                    width: 0;
                    border-top: 1px solid $dark-color;
                }

                &:hover:after {
                    width: 100%;
                }
            }

            > ul {
                position: absolute;
                top: 100%;
                left: -30px;
                z-index: 998;
                width: 220px;
                background-color: darken($dark-color, 20%);
                color: $light-color;
                padding: 0 30px;
                display: none;

                li a {
                    display: block;
                    line-height: 60px;
                    border-bottom: 1px solid $dark-color;

                    &:hover {
                        color: $main-color;
                    }
                }
            }

            &:hover > ul {
                display: block;
                animation: fadeInUp $duration-time both;
            }
        }
    }

    .ro-main-nav-style-2 {
        .ro-nav-content {
            padding: 15px 30px;
        }
    }

    .ro-main-nav-style-3 {
        .ro-nav-content {
            background-color: rgba($light-color, 0.5);
            margin-top: 10px;

            > li > a {
                padding: 30px 0;
            }
        }
    }

    .ro-main-nav-shop {
        .ro-nav {
            margin-left: -80px;
            margin-right: -80px;

            li {
                margin: 0 30px;
            }
        }
    }
}


@include breakpoint-in($screen-sm, $screen-lg) {
    .ro-main-nav-style-1 {
        .ro-brand {
            img {
                display: inline-block;
                margin: 5px;
            }
        }
    }

    .ro-main-nav-style-3 {
        .ro-nav-content {
            > li > a {
                padding: 20px 0;
            }
        }
    }
}


@include breakpoint-in($screen-sm, $screen-md) {
    .ro-main-nav-style-3 {
        .ro-brand {
            @include center-block;
            max-width: 240px;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .ro-nav-content {
            margin-top: 0;
        }
    }

    .ro-main-nav-style-2 {
        padding: 0;

        .ro-brand {
            @include center-block;
            height: 70px;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .ro-nav-content {
            padding: 0 30px;
        }
    }
}


@include breakpoint-max($screen-sm) {
    .ro-nav-content {
        transition: right $duration-time;
        font-family: 'aqua', sans-serif;
        font-weight: 400;
        font-size: 14px;
        display: block;
        position: fixed;
        height: 100vh;
        top: 0;
        right: -$main-nav-width;
        width: $main-nav-width;
        padding: 50px;
        z-index: 998;
        overflow: auto;
        background-color: $lightgray-color;

        .ro-main-nav-opened & {
            right: 0;
        }

        > li {
            > ul > li {
                @include easing-animation;
                height: 0;
                overflow: hidden;
            }

            &:hover {
                > ul > li {
                    height: 70px;
                }
            }
        }

        > li:not(:first-child) a {
            display: block;
            padding: 20px 0;
            border-bottom: 1px solid $gray-color;

            &:hover {
                border-color: $dark-color;
            }
        }

        > li:first-child {
            margin-bottom: 40px;
        }
    }

    .ro-main-nav-style-1 {
        padding-top: 0;

        .ro-brand {
            height: 70px;
            padding: 10px 0;
            text-align: left;
            float: left;
            margin-bottom: 0;

            img {
                height: 100%;
                display: inline-block;
                margin-right: 10px;
            }
        }

        &.ro-v-logo .ro-brand {
            padding: 5px 0;
        }
    }

    .ro-main-nav-style-2 {
        padding: 0;

        .ro-brand {
            height: 70px;
            width: auto;
            padding: 10px 0;
            float: left;
        }
    }

    .ro-main-nav-style-3 {
        top: 10px;

        .ro-brand {
            height: 70px;
            width: auto;
            padding: 10px 0;
            float: left;
        }
    }
}