@charset "UTF-8";
/*Theme Name : Aqua Spa
Theme URI    : http://themeforest.net/user/themeroyal
Author       : THEMEROYAL
Author URI   : http://themeforest.net/user/themeroyal
Version      : 1.0
Text Domain  : progression
/*-------------------------------------------------------*/
/*
* Component module
*/
/* + TYPOGRAPHY */
/* + COLOR */
/* + INPUT */
/* + ICON */
/* + BUTTON */
/* + UTILITIES */
/* + SECTION */
/*
* Common module
*/
/* + RESERVATION AND CONTACT */
/* + NAVIGATOR */
/*
* Single module
*/
/* + HEADER */
/* + FOOTER */
/* + TEAM */
/* + SERVICE */
/* + MEMBERSHIP */
/* + TESTIMONIAL */
/* + HOME */
/* + ABOUT */
/* + SHOP */
/* + SPACE */
/* + ADVERTISE */
/* + BLOG */
/* + OTHER PAGE */
/*
* Component module
*/
/* TYPOGRAPHY */
body {
  font-family: "Josefin Sans", sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #444444;
  line-height: 1.8;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased; }

.ro-font-regular {
  font-weight: 400; }

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
  margin-bottom: 25px;
  letter-spacing: 0.1em; }
  h1.ro-font-aqua, h2.ro-font-aqua, h3.ro-font-aqua, h4.ro-font-aqua, h5.ro-font-aqua, h6.ro-font-aqua {
    font-family: 'aqua';
    font-weight: 400; }
  h1 .ro-heading-h, h2 .ro-heading-h, h3 .ro-heading-h, h4 .ro-heading-h, h5 .ro-heading-h, h6 .ro-heading-h {
    line-height: 1.6; }

.ro-light-text {
  font-weight: 400; }

.ro-hr-heading {
  text-align: center; }
  .ro-hr-heading:after {
    display: block;
    margin-right: auto;
    margin-left: auto;
    content: " ";
    display: block;
    width: 30px;
    margin-top: 15px;
    height: 0;
    border-top: 1px solid #444444; }
  .ro-hr-heading.ro-left {
    text-align: left; }
    .ro-hr-heading.ro-left:after {
      margin-left: 0; }
  .ro-hr-heading.ro-right {
    text-align: right; }
    .ro-hr-heading.ro-right:after {
      margin-right: 0; }

.ro-hr {
  display: block;
  margin-right: auto;
  margin-left: auto;
  width: 80%;
  border-top: 1px solid #AAAAAA;
  margin: 0 auto; }
  .ro-hr.ro-full {
    width: 100%; }
  .ro-hr .ro-color-light {
    border-color: #FFFFFF; }

p {
  margin-bottom: 25px; }

h1 {
  font-size: 42px; }

h2 {
  font-size: 36px; }

h3 {
  font-size: 24px; }

h4 {
  font-size: 18px; }

h5 {
  font-size: 16px; }

h6 {
  font-size: 14px; }

.ro-font-size-1 {
  font-size: 42px; }

.ro-font-size-2 {
  font-size: 36px; }

.ro-font-size-3 {
  font-size: 24px; }

.ro-font-size-4 {
  font-size: 18px; }

.ro-font-size-5 {
  font-size: 16px; }

.ro-font-size-6 {
  font-size: 14px; }

::selection {
  background: #84C340;
  color: #FFFFFF; }

.ro-ellipsis {
  overflow: hidden; }

.ro-quote-content {
  padding-top: 30px;
  margin-bottom: 0;
  font-style: italic;
  font-weight: 400; }
  .ro-quote-content q:before, .ro-quote-content q:after {
    font-style: italic;
    font-size: 30px;
    line-height: 16px;
    font-weight: 600; }
  .ro-quote-content q:before {
    content: open-quote; }
  .ro-quote-content q:after {
    content: close-quote; }
  .ro-quote-content .ro-author-name {
    padding-top: 20px;
    font-weight: 700;
    text-align: right;
    color: #84C340; }

/* COLOR */
.ro-color-main {
  color: #84C340; }

.ro-color-light {
  color: #FFFFFF; }

.ro-color-dark {
  color: #444444; }

.ro-bg-dark {
  background-color: #2b2b2b; }

.ro-bg-light {
  background-color: #FFFFFF; }

.ro-bg-trans-light {
  background-color: rgba(255, 255, 255, 0.7); }

.ro-bg-main {
  background-color: #84C340; }

.ro-bgc-1 {
  background-color: #C6D9C6; }

.ro-bgc-2 {
  background-color: #D9D8C1; }

.ro-bgc-3 {
  background-color: #DEB6B6; }

.ro-bgc-4 {
  background-color: #ABDCE1; }

.ro-bgc-5 {
  background-color: #E7E6E1; }

.ro-bgc-trans-1 {
  background-color: rgba(198, 217, 198, 0.7); }

.ro-bgc-trans-2 {
  background-color: rgba(217, 216, 193, 0.7); }

.ro-bgc-trans-3 {
  background-color: rgba(222, 182, 182, 0.7); }

.ro-bgc-trans-4 {
  background-color: rgba(171, 220, 225, 0.7); }

.ro-bgc-trans-5 {
  background-color: rgba(231, 230, 225, 0.7); }

/* INPUT */
input[type="text"], input[type="email"], input[type="search"], textarea, select {
  transition: all 0.4s;
  width: 100%;
  outline: none;
  background-color: #FFFFFF;
  border: 1px solid #444444;
  padding: 5px 15px; }
  input[type="text"]:focus, input[type="email"]:focus, input[type="search"]:focus, textarea:focus, select:focus {
    border-color: #84C340; }

select {
  height: 40px; }

textarea {
  resize: none;
  height: 115px; }

.placeholder {
  color: #444444; }

::-webkit-input-placeholder {
  color: #444444; }

:-moz-placeholder {
  /* Firefox 18- */
  color: #444444; }

::-moz-placeholder {
  /* Firefox 19+ */
  color: #444444; }

:-ms-input-placeholder {
  color: #444444; }

/* ICON */
.ro-social li {
  display: inline-block;
  margin-right: 3px;
  margin-left: 3px;
  font-size: 16px; }
  .ro-social li a:hover {
    color: #84C340; }

/* BUTTON */
.ro-btn-1 {
  transition: all 0.4s;
  display: inline-block;
  padding: 11px 40px;
  font-size: 12px;
  font-weight: 700;
  outline: none;
  word-spacing: 2px;
  line-height: 1.2;
  text-align: center;
  background-color: transparent;
  border: 0;
  background-color: #84C340;
  color: #FFFFFF; }
  .ro-btn-1:hover {
    background-color: #444444;
    color: #FFFFFF; }

.ro-btn-2 {
  transition: all 0.4s;
  display: inline-block;
  padding: 11px 40px;
  font-size: 12px;
  font-weight: 700;
  outline: none;
  word-spacing: 2px;
  line-height: 1.2;
  text-align: center;
  background-color: transparent;
  border: 0;
  background-color: #444444;
  color: #FFFFFF; }
  .ro-btn-2:hover {
    background-color: #84C340;
    color: #FFFFFF; }

.ro-btn-3 {
  transition: all 0.4s;
  display: inline-block;
  padding: 11px 40px;
  font-size: 12px;
  font-weight: 700;
  outline: none;
  word-spacing: 2px;
  line-height: 1.2;
  text-align: center;
  background-color: transparent;
  border: 0;
  background-color: #FFFFFF; }
  .ro-btn-3:hover {
    background-color: #84C340;
    color: #FFFFFF; }

.ro-btn-bd-1 {
  transition: all 0.4s;
  display: inline-block;
  padding: 11px 40px;
  font-size: 12px;
  font-weight: 700;
  outline: none;
  word-spacing: 2px;
  line-height: 1.2;
  text-align: center;
  background-color: transparent;
  border: 0;
  padding: 10px 39px;
  border: 1px solid #444444; }
  .ro-btn-bd-1:hover {
    background-color: #84C340;
    border-color: #84C340;
    color: #FFFFFF; }

.ro-btn-bd-2 {
  transition: all 0.4s;
  display: inline-block;
  padding: 11px 40px;
  font-size: 12px;
  font-weight: 700;
  outline: none;
  word-spacing: 2px;
  line-height: 1.2;
  text-align: center;
  background-color: transparent;
  border: 0;
  padding: 10px 39px;
  border: 1px solid #444444;
  background-color: #FFFFFF; }
  .ro-btn-bd-2:hover {
    background-color: #84C340;
    border-color: #84C340;
    color: #FFFFFF; }

.ro-btn-bdr-1 {
  transition: all 0.4s;
  display: inline-block;
  padding: 11px 40px;
  font-size: 12px;
  font-weight: 700;
  outline: none;
  word-spacing: 2px;
  line-height: 1.2;
  text-align: center;
  background-color: transparent;
  border: 0;
  padding: 10px 39px;
  border: 1px solid #444444;
  border-radius: 20px;
  background-color: #EEEEEE; }

.ro-btn-bdr-2 {
  transition: all 0.4s;
  display: inline-block;
  padding: 11px 40px;
  font-size: 12px;
  font-weight: 700;
  outline: none;
  word-spacing: 2px;
  line-height: 1.2;
  text-align: center;
  background-color: transparent;
  border: 0;
  padding: 10px 39px;
  border: 1px solid #444444;
  background-color: #84C340;
  border-radius: 20px; }

/* UTILITIES */
/* Reset */
a, a:hover, a:focus, a:active, a.active {
  transition: all 0.4s;
  outline: none;
  text-decoration: none;
  color: inherit; }

ul {
  padding: 0;
  margin: 0; }
  ul li {
    padding: 0;
    margin: 0;
    list-style: none; }
  ul.ro-list-inline-block li {
    display: inline-block; }

/* Special div */
.ro-nopadding {
  padding: 0 !important; }

.ro-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 512; }

.ro-overlay-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1; }

.ro-col-nopadding {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.ro-relative {
  position: relative; }

.ro-absolute {
  position: absolute; }

.ro-fixed {
  position: fixed; }

.ro-middle {
  position: relative;
  display: block;
  top: 50%;
  transform: translateY(-50%); }

.ro-center-block {
  display: block;
  margin-right: auto;
  margin-left: auto; }

.ro-center {
  position: relative;
  display: block;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  margin-right: auto;
  margin-left: auto; }

.ro-ab-bottom {
  position: absolute;
  top: 100%;
  transform: translateY(-100%); }
  .ro-ab-bottom:before, .ro-ab-bottom:after {
    content: " ";
    display: table; }
  .ro-ab-bottom:after {
    clear: both; }

.ro-bottom {
  position: relative;
  display: block;
  top: 100%;
  transform: translateY(-100%); }

.ro-ab-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }
  .ro-ab-center:before, .ro-ab-center:after {
    content: " ";
    display: table; }
  .ro-ab-center:after {
    clear: both; }

.ro-cell-vertical-wrapper {
  width: 100%;
  height: 100%;
  display: table; }
  .ro-cell-vertical-wrapper .ro-cell-middle {
    display: table-cell;
    vertical-align: middle; }
  .ro-cell-vertical-wrapper .ro-cell-bottom {
    display: table-cell;
    vertical-align: bottom; }
  .ro-cell-vertical-wrapper .ro-cell-top {
    display: table-cell;
    vertical-align: top; }

/* Space */
.ro-m-0 {
  margin: 0px; }

.ro-mt-0 {
  margin-top: 0px; }

.ro-mb-0 {
  margin-bottom: 0px; }

.ro-ml-0 {
  margin-left: 0px; }

.ro-mr-0 {
  margin-right: 0px; }

.ro-p-0 {
  padding: 0px; }

.ro-pt-0 {
  padding-top: 0px; }

.ro-pb-0 {
  padding-bottom: 0px; }

.ro-pl-0 {
  padding-left: 0px; }

.ro-pr-0 {
  padding-right: 0px; }

.ro-m-5 {
  margin: 5px; }

.ro-mt-5 {
  margin-top: 5px; }

.ro-mb-5 {
  margin-bottom: 5px; }

.ro-ml-5 {
  margin-left: 5px; }

.ro-mr-5 {
  margin-right: 5px; }

.ro-p-5 {
  padding: 5px; }

.ro-pt-5 {
  padding-top: 5px; }

.ro-pb-5 {
  padding-bottom: 5px; }

.ro-pl-5 {
  padding-left: 5px; }

.ro-pr-5 {
  padding-right: 5px; }

.ro-m-10 {
  margin: 10px; }

.ro-mt-10 {
  margin-top: 10px; }

.ro-mb-10 {
  margin-bottom: 10px; }

.ro-ml-10 {
  margin-left: 10px; }

.ro-mr-10 {
  margin-right: 10px; }

.ro-p-10 {
  padding: 10px; }

.ro-pt-10 {
  padding-top: 10px; }

.ro-pb-10 {
  padding-bottom: 10px; }

.ro-pl-10 {
  padding-left: 10px; }

.ro-pr-10 {
  padding-right: 10px; }

.ro-m-15 {
  margin: 15px; }

.ro-mt-15 {
  margin-top: 15px; }

.ro-mb-15 {
  margin-bottom: 15px; }

.ro-ml-15 {
  margin-left: 15px; }

.ro-mr-15 {
  margin-right: 15px; }

.ro-p-15 {
  padding: 15px; }

.ro-pt-15 {
  padding-top: 15px; }

.ro-pb-15 {
  padding-bottom: 15px; }

.ro-pl-15 {
  padding-left: 15px; }

.ro-pr-15 {
  padding-right: 15px; }

.ro-m-20 {
  margin: 20px; }

.ro-mt-20 {
  margin-top: 20px; }

.ro-mb-20 {
  margin-bottom: 20px; }

.ro-ml-20 {
  margin-left: 20px; }

.ro-mr-20 {
  margin-right: 20px; }

.ro-p-20 {
  padding: 20px; }

.ro-pt-20 {
  padding-top: 20px; }

.ro-pb-20 {
  padding-bottom: 20px; }

.ro-pl-20 {
  padding-left: 20px; }

.ro-pr-20 {
  padding-right: 20px; }

.ro-m-25 {
  margin: 25px; }

.ro-mt-25 {
  margin-top: 25px; }

.ro-mb-25 {
  margin-bottom: 25px; }

.ro-ml-25 {
  margin-left: 25px; }

.ro-mr-25 {
  margin-right: 25px; }

.ro-p-25 {
  padding: 25px; }

.ro-pt-25 {
  padding-top: 25px; }

.ro-pb-25 {
  padding-bottom: 25px; }

.ro-pl-25 {
  padding-left: 25px; }

.ro-pr-25 {
  padding-right: 25px; }

.ro-m-30 {
  margin: 30px; }

.ro-mt-30 {
  margin-top: 30px; }

.ro-mb-30 {
  margin-bottom: 30px; }

.ro-ml-30 {
  margin-left: 30px; }

.ro-mr-30 {
  margin-right: 30px; }

.ro-p-30 {
  padding: 30px; }

.ro-pt-30 {
  padding-top: 30px; }

.ro-pb-30 {
  padding-bottom: 30px; }

.ro-pl-30 {
  padding-left: 30px; }

.ro-pr-30 {
  padding-right: 30px; }

.ro-m-35 {
  margin: 35px; }

.ro-mt-35 {
  margin-top: 35px; }

.ro-mb-35 {
  margin-bottom: 35px; }

.ro-ml-35 {
  margin-left: 35px; }

.ro-mr-35 {
  margin-right: 35px; }

.ro-p-35 {
  padding: 35px; }

.ro-pt-35 {
  padding-top: 35px; }

.ro-pb-35 {
  padding-bottom: 35px; }

.ro-pl-35 {
  padding-left: 35px; }

.ro-pr-35 {
  padding-right: 35px; }

.ro-m-40 {
  margin: 40px; }

.ro-mt-40 {
  margin-top: 40px; }

.ro-mb-40 {
  margin-bottom: 40px; }

.ro-ml-40 {
  margin-left: 40px; }

.ro-mr-40 {
  margin-right: 40px; }

.ro-p-40 {
  padding: 40px; }

.ro-pt-40 {
  padding-top: 40px; }

.ro-pb-40 {
  padding-bottom: 40px; }

.ro-pl-40 {
  padding-left: 40px; }

.ro-pr-40 {
  padding-right: 40px; }

.ro-m-45 {
  margin: 45px; }

.ro-mt-45 {
  margin-top: 45px; }

.ro-mb-45 {
  margin-bottom: 45px; }

.ro-ml-45 {
  margin-left: 45px; }

.ro-mr-45 {
  margin-right: 45px; }

.ro-p-45 {
  padding: 45px; }

.ro-pt-45 {
  padding-top: 45px; }

.ro-pb-45 {
  padding-bottom: 45px; }

.ro-pl-45 {
  padding-left: 45px; }

.ro-pr-45 {
  padding-right: 45px; }

.ro-m-50 {
  margin: 50px; }

.ro-mt-50 {
  margin-top: 50px; }

.ro-mb-50 {
  margin-bottom: 50px; }

.ro-ml-50 {
  margin-left: 50px; }

.ro-mr-50 {
  margin-right: 50px; }

.ro-p-50 {
  padding: 50px; }

.ro-pt-50 {
  padding-top: 50px; }

.ro-pb-50 {
  padding-bottom: 50px; }

.ro-pl-50 {
  padding-left: 50px; }

.ro-pr-50 {
  padding-right: 50px; }

.ro-m-55 {
  margin: 55px; }

.ro-mt-55 {
  margin-top: 55px; }

.ro-mb-55 {
  margin-bottom: 55px; }

.ro-ml-55 {
  margin-left: 55px; }

.ro-mr-55 {
  margin-right: 55px; }

.ro-p-55 {
  padding: 55px; }

.ro-pt-55 {
  padding-top: 55px; }

.ro-pb-55 {
  padding-bottom: 55px; }

.ro-pl-55 {
  padding-left: 55px; }

.ro-pr-55 {
  padding-right: 55px; }

.ro-m-60 {
  margin: 60px; }

.ro-mt-60 {
  margin-top: 60px; }

.ro-mb-60 {
  margin-bottom: 60px; }

.ro-ml-60 {
  margin-left: 60px; }

.ro-mr-60 {
  margin-right: 60px; }

.ro-p-60 {
  padding: 60px; }

.ro-pt-60 {
  padding-top: 60px; }

.ro-pb-60 {
  padding-bottom: 60px; }

.ro-pl-60 {
  padding-left: 60px; }

.ro-pr-60 {
  padding-right: 60px; }

.ro-m-65 {
  margin: 65px; }

.ro-mt-65 {
  margin-top: 65px; }

.ro-mb-65 {
  margin-bottom: 65px; }

.ro-ml-65 {
  margin-left: 65px; }

.ro-mr-65 {
  margin-right: 65px; }

.ro-p-65 {
  padding: 65px; }

.ro-pt-65 {
  padding-top: 65px; }

.ro-pb-65 {
  padding-bottom: 65px; }

.ro-pl-65 {
  padding-left: 65px; }

.ro-pr-65 {
  padding-right: 65px; }

.ro-m-70 {
  margin: 70px; }

.ro-mt-70 {
  margin-top: 70px; }

.ro-mb-70 {
  margin-bottom: 70px; }

.ro-ml-70 {
  margin-left: 70px; }

.ro-mr-70 {
  margin-right: 70px; }

.ro-p-70 {
  padding: 70px; }

.ro-pt-70 {
  padding-top: 70px; }

.ro-pb-70 {
  padding-bottom: 70px; }

.ro-pl-70 {
  padding-left: 70px; }

.ro-pr-70 {
  padding-right: 70px; }

.ro-m-75 {
  margin: 75px; }

.ro-mt-75 {
  margin-top: 75px; }

.ro-mb-75 {
  margin-bottom: 75px; }

.ro-ml-75 {
  margin-left: 75px; }

.ro-mr-75 {
  margin-right: 75px; }

.ro-p-75 {
  padding: 75px; }

.ro-pt-75 {
  padding-top: 75px; }

.ro-pb-75 {
  padding-bottom: 75px; }

.ro-pl-75 {
  padding-left: 75px; }

.ro-pr-75 {
  padding-right: 75px; }

.ro-m-80 {
  margin: 80px; }

.ro-mt-80 {
  margin-top: 80px; }

.ro-mb-80 {
  margin-bottom: 80px; }

.ro-ml-80 {
  margin-left: 80px; }

.ro-mr-80 {
  margin-right: 80px; }

.ro-p-80 {
  padding: 80px; }

.ro-pt-80 {
  padding-top: 80px; }

.ro-pb-80 {
  padding-bottom: 80px; }

.ro-pl-80 {
  padding-left: 80px; }

.ro-pr-80 {
  padding-right: 80px; }

.ro-m-85 {
  margin: 85px; }

.ro-mt-85 {
  margin-top: 85px; }

.ro-mb-85 {
  margin-bottom: 85px; }

.ro-ml-85 {
  margin-left: 85px; }

.ro-mr-85 {
  margin-right: 85px; }

.ro-p-85 {
  padding: 85px; }

.ro-pt-85 {
  padding-top: 85px; }

.ro-pb-85 {
  padding-bottom: 85px; }

.ro-pl-85 {
  padding-left: 85px; }

.ro-pr-85 {
  padding-right: 85px; }

.ro-m-90 {
  margin: 90px; }

.ro-mt-90 {
  margin-top: 90px; }

.ro-mb-90 {
  margin-bottom: 90px; }

.ro-ml-90 {
  margin-left: 90px; }

.ro-mr-90 {
  margin-right: 90px; }

.ro-p-90 {
  padding: 90px; }

.ro-pt-90 {
  padding-top: 90px; }

.ro-pb-90 {
  padding-bottom: 90px; }

.ro-pl-90 {
  padding-left: 90px; }

.ro-pr-90 {
  padding-right: 90px; }

.ro-m-95 {
  margin: 95px; }

.ro-mt-95 {
  margin-top: 95px; }

.ro-mb-95 {
  margin-bottom: 95px; }

.ro-ml-95 {
  margin-left: 95px; }

.ro-mr-95 {
  margin-right: 95px; }

.ro-p-95 {
  padding: 95px; }

.ro-pt-95 {
  padding-top: 95px; }

.ro-pb-95 {
  padding-bottom: 95px; }

.ro-pl-95 {
  padding-left: 95px; }

.ro-pr-95 {
  padding-right: 95px; }

/* Video */
.ro-video-bg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: relative;
  width: 100%; }
  .ro-video-bg:before, .ro-video-bg:after {
    content: " ";
    display: table; }
  .ro-video-bg:after {
    clear: both; }
  .ro-video-h .ro-video-bg {
    width: auto;
    height: 100%; }

/* SECTION */
.ro-section {
  position: relative;
  display: block;
  width: 100%;
  float: left; }
  .ro-section .ro-section-mb {
    margin-bottom: 50px; }
  .ro-section .ro-section-mb-small {
    margin-bottom: 30px; }
  .ro-section .ro-section-mb-big {
    margin-bottom: 80px; }
  .ro-section .ro-section-heading {
    margin-bottom: 40px; }
  .ro-section.ro-padding-top {
    padding-top: 50px; }
  .ro-section.ro-padding-top-big {
    padding-top: 80px; }
  .ro-section.ro-margin-bottom {
    margin-bottom: 80px; }

/*
* Common module
*/
/* RESERVATION AND CONTACT */
.ro-reservation-bg .ro-reservation-heading {
  display: none; }
.ro-reservation-bg .ro-light-text {
  font-weight: 700; }

.ro-reservation-bg .ro-reservation {
  position: relative;
  z-index: 1;
  background-image: url("../images/reservation.jpg");
  background-size: cover;
  border: 1px solid #AAAAAA;
  margin-bottom: 80px;
  margin-top: 30px;
  padding-top: 85px; }
  .ro-reservation-bg .ro-reservation:after {
    content: " ";
    position: absolute;
    display: block;
    background: rgba(255, 255, 255, 0.9);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1; }
.ro-reservation input, .ro-reservation select, .ro-reservation option {
  margin-bottom: 30px; }
.ro-reservation .ro-reservation-form .ro-submit {
  font-size: 16px;
  width: 100%;
  height: 40px; }

.ro-contact-form input, .ro-contact-form select, .ro-contact-form option, .ro-contact-form textarea {
  margin-bottom: 20px;
  float: left; }

.ro-map-iframe {
  width: 100%;
  height: 435px;
  margin-bottom: 30px;
  padding: 10px;
  border: 1px solid #AAAAAA; }

.ro-contact-info-wrapper {
  margin-bottom: 30px; }
  .ro-contact-info-wrapper .ro-contact-info {
    position: relative;
    padding-left: 24px;
    line-height: 2.5; }
    .ro-contact-info-wrapper .ro-contact-info:before {
      position: absolute;
      left: 0;
      top: 0; }

@media (max-width: 991px) {
  .ro-contact-info-wrapper .ro-contact-info {
    display: block;
    margin-right: auto;
    margin-left: auto; }
  .ro-contact-form .pull-right, .ro-contact-form .pull-left {
    float: none !important; } }

/* NAVIGATOR */
.ro-hamburger {
  transition: all 0.4s;
  top: 25px;
  display: block;
  position: relative;
  background: transparent;
  border: 0;
  width: 22px;
  height: 14px;
  padding: 0;
  outline: none;
  cursor: pointer;
  z-index: 999; }
  .ro-hamburger::before, .ro-hamburger::after, .ro-hamburger span {
    transition: all 0.4s;
    background: #444444; }
  .ro-hamburger::before, .ro-hamburger::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 2px;
    width: 100%;
    left: 0;
    top: 50%;
    transform-origin: 50% 50%; }
  .ro-hamburger span {
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    top: 50%;
    overflow: hidden;
    text-indent: 200%; }
  .ro-hamburger::before {
    transform: translate3d(0, -7px, 0); }
  .ro-hamburger::after {
    transform: translate3d(0, 7px, 0); }
  .ro-main-nav-opened .ro-hamburger {
    position: fixed;
    right: 15px; }
    .ro-main-nav-opened .ro-hamburger span {
      opacity: 0; }
    .ro-main-nav-opened .ro-hamburger::before {
      transform: rotate3d(0, 0, 1, 45deg); }
    .ro-main-nav-opened .ro-hamburger::after {
      transform: rotate3d(0, 0, 1, -45deg); }

#ro-backtop {
  transition: all 0.4s;
  width: 40px;
  height: 40px;
  border: 1px solid #444444;
  position: fixed;
  display: none;
  bottom: 50px;
  right: 30px;
  background-color: #FFFFFF;
  z-index: 992;
  text-align: center;
  font-size: 24px;
  line-height: 38px;
  text-align: center;
  outline: none; }
  #ro-backtop:hover, #ro-backtop:focus {
    color: #FFFFFF;
    background-color: #84C340;
    border-color: #84C340; }
  #ro-backtop.ro-show {
    display: block;
    animation: bounceInUp 0.7s both; }

/* nav style 1 */
.ro-main-nav-style-1 {
  position: relative;
  padding-top: 50px;
  text-align: center; }
  .ro-main-nav-style-1 .ro-brand {
    position: relative;
    display: inline-block;
    margin-bottom: 5px; }
    .ro-main-nav-style-1 .ro-brand img {
      display: block;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 20px; }

.ro-main-nav-style-2 {
  position: relative;
  padding: 50px 0; }
  .ro-main-nav-style-2 .ro-brand {
    display: inline-block;
    height: 85px; }

.ro-main-nav-style-3 {
  position: absolute;
  width: 100%;
  top: 40px;
  z-index: 998; }
  .ro-main-nav-style-3 .ro-brand {
    width: 100%; }
  body:not(.ro-main-nav-opened) .ro-main-nav-style-3 .ro-hamburger span, body:not(.ro-main-nav-opened) .ro-main-nav-style-3 .ro-hamburger:after, body:not(.ro-main-nav-opened) .ro-main-nav-style-3 .ro-hamburger:before {
    background-color: #FFFFFF; }

.ro-main-nav-shop {
  text-align: center; }
  .ro-main-nav-shop .ro-brand {
    border: 2px solid #444444;
    display: inline-block;
    padding: 10px 15px;
    height: 80px;
    margin: 50px 0; }
    .ro-main-nav-shop .ro-brand img {
      height: 100%; }
  .ro-main-nav-shop .ro-cart {
    position: absolute;
    top: -40px;
    font-size: 24px;
    right: 15px; }
    .ro-main-nav-shop .ro-cart:before {
      width: 20px;
      height: 20px;
      position: absolute;
      display: block;
      content: attr(data-content);
      font-size: 14px;
      line-height: 20px;
      top: 0;
      right: -12px;
      border-radius: 10px;
      background-color: #84C340;
      color: #FFFFFF; }
  .ro-main-nav-shop .ro-nav-content-wrapper {
    position: relative;
    border-top: 1px solid #AAAAAA;
    border-bottom: 1px solid #AAAAAA; }
  .ro-main-nav-shop .ro-nav, .ro-main-nav-shop .ro-hotline, .ro-main-nav-shop .ro-option {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    line-height: 50px; }
    .ro-main-nav-shop .ro-nav i, .ro-main-nav-shop .ro-hotline i, .ro-main-nav-shop .ro-option i {
      font-size: 8px;
      margin-left: 5px; }
  .ro-main-nav-shop .ro-nav li {
    font-family: "aqua",sans-serif;
    font-weight: 400;
    font-size: 14px;
    display: inline-block;
    margin: 0 10px;
    padding: 12px 0;
    line-height: 26px; }
    .ro-main-nav-shop .ro-nav li a {
      position: relative; }
      .ro-main-nav-shop .ro-nav li a:after {
        transition: all 0.4s;
        display: block;
        margin-right: auto;
        margin-left: auto;
        content: " ";
        height: 0;
        width: 0;
        border-top: 1px solid #444444; }
      .ro-main-nav-shop .ro-nav li a:hover:after {
        width: 100%; }
  .ro-main-nav-shop .dropdown-toggle:hover {
    color: #84C340; }
  .ro-main-nav-shop .btn, .ro-main-nav-shop .dropdown-menu > li > a {
    font-weight: inherit; }
  .ro-main-nav-shop .dropdown-menu, .ro-main-nav-shop .btn-group.open .dropdown-toggle {
    box-shadow: none; }
  .ro-main-nav-shop .btn-group.open .dropdown-toggle {
    background-color: #84C340;
    color: #FFFFFF; }

@media (min-width: 768px) {
  .ro-nav-content {
    position: relative;
    display: table;
    width: 100%;
    font-family: 'aqua', sans-serif;
    font-weight: 400;
    font-size: 14px;
    padding: 0 30px; }
    .ro-nav-content > li {
      position: relative;
      display: table-cell;
      width: 300px; }
      .ro-nav-content > li > a {
        display: inline-block;
        position: relative;
        padding: 15px 0; }
        .ro-nav-content > li > a:after {
          transition: all 0.4s;
          display: block;
          margin-right: auto;
          margin-left: auto;
          content: " ";
          height: 0;
          width: 0;
          border-top: 1px solid #444444; }
        .ro-nav-content > li > a:hover:after {
          width: 100%; }
      .ro-nav-content > li > ul {
        position: absolute;
        top: 100%;
        left: -30px;
        z-index: 998;
        width: 220px;
        background-color: #111111;
        color: #FFFFFF;
        padding: 0 30px;
        display: none; }
        .ro-nav-content > li > ul li a {
          display: block;
          line-height: 60px;
          border-bottom: 1px solid #444444; }
          .ro-nav-content > li > ul li a:hover {
            color: #84C340; }
      .ro-nav-content > li:hover > ul {
        display: block;
        animation: fadeInUp 0.4s both; }
  .ro-main-nav-style-2 .ro-nav-content {
    padding: 15px 30px; }
  .ro-main-nav-style-3 .ro-nav-content {
    background-color: rgba(255, 255, 255, 0.5);
    margin-top: 10px; }
    .ro-main-nav-style-3 .ro-nav-content > li > a {
      padding: 30px 0; }
  .ro-main-nav-shop .ro-nav {
    margin-left: -80px;
    margin-right: -80px; }
    .ro-main-nav-shop .ro-nav li {
      margin: 0 30px; } }

@media (min-width: 768px) and (max-width: 1199px) {
  .ro-main-nav-style-1 .ro-brand img {
    display: inline-block;
    margin: 5px; }
  .ro-main-nav-style-3 .ro-nav-content > li > a {
    padding: 20px 0; } }

@media (min-width: 768px) and (max-width: 991px) {
  .ro-main-nav-style-3 .ro-brand {
    display: block;
    margin-right: auto;
    margin-left: auto;
    max-width: 240px;
    margin-top: 10px;
    margin-bottom: 10px; }
  .ro-main-nav-style-3 .ro-nav-content {
    margin-top: 0; }
  .ro-main-nav-style-2 {
    padding: 0; }
    .ro-main-nav-style-2 .ro-brand {
      display: block;
      margin-right: auto;
      margin-left: auto;
      height: 70px;
      margin-top: 10px;
      margin-bottom: 10px; }
    .ro-main-nav-style-2 .ro-nav-content {
      padding: 0 30px; } }

@media (max-width: 767px) {
  .ro-nav-content {
    transition: right 0.4s;
    font-family: 'aqua', sans-serif;
    font-weight: 400;
    font-size: 14px;
    display: block;
    position: fixed;
    height: 100vh;
    top: 0;
    right: -270px;
    width: 270px;
    padding: 50px;
    z-index: 998;
    overflow: auto;
    background-color: #EEEEEE; }
    .ro-main-nav-opened .ro-nav-content {
      right: 0; }
    .ro-nav-content > li > ul > li {
      transition: all 0.4s;
      height: 0;
      overflow: hidden; }
    .ro-nav-content > li:hover > ul > li {
      height: 70px; }
    .ro-nav-content > li:not(:first-child) a {
      display: block;
      padding: 20px 0;
      border-bottom: 1px solid #AAAAAA; }
      .ro-nav-content > li:not(:first-child) a:hover {
        border-color: #444444; }
    .ro-nav-content > li:first-child {
      margin-bottom: 40px; }
  .ro-main-nav-style-1 {
    padding-top: 0; }
    .ro-main-nav-style-1 .ro-brand {
      height: 70px;
      padding: 10px 0;
      text-align: left;
      float: left;
      margin-bottom: 0; }
      .ro-main-nav-style-1 .ro-brand img {
        height: 100%;
        display: inline-block;
        margin-right: 10px; }
    .ro-main-nav-style-1.ro-v-logo .ro-brand {
      padding: 5px 0; }
  .ro-main-nav-style-2 {
    padding: 0; }
    .ro-main-nav-style-2 .ro-brand {
      height: 70px;
      width: auto;
      padding: 10px 0;
      float: left; }
  .ro-main-nav-style-3 {
    top: 10px; }
    .ro-main-nav-style-3 .ro-brand {
      height: 70px;
      width: auto;
      padding: 10px 0;
      float: left; } }

/*
* Single module
*/
/* HEADER */
/* FOOTER */
.ro-main-footer {
  padding-top: 60px;
  padding-bottom: 30px;
  border-top: 1px solid #AAAAAA; }
  .ro-main-footer .ro-open-time p {
    margin-bottom: 15px; }
  .ro-main-footer .ro-footer-separator {
    display: block;
    margin-right: auto;
    margin-left: auto;
    height: 220px;
    width: 0;
    border-left: 1px solid #AAAAAA; }
  .ro-main-footer .ro-footer-item {
    margin-bottom: 50px; }
  .ro-main-footer .ro-footer-newletter {
    position: relative;
    margin-bottom: 30px; }
    .ro-main-footer .ro-footer-newletter a {
      transition: all 0.4s;
      position: absolute;
      display: block;
      height: 40px;
      width: 40px;
      line-height: 40px;
      text-align: center;
      top: 0;
      right: 0;
      background-color: #444444;
      color: #FFFFFF; }
      .ro-main-footer .ro-footer-newletter a:hover {
        background-color: #84C340; }
  .ro-main-footer .ro-social {
    text-align: right; }
    .ro-main-footer .ro-social li {
      margin-right: 5px;
      margin-left: 0; }
  .ro-bg-dark .ro-main-footer {
    color: #AAAAAA;
    border-top: 0; }
    .ro-bg-dark .ro-main-footer h5 {
      color: #FFFFFF; }
    .ro-bg-dark .ro-main-footer .ro-btn-bd-1 {
      color: #FFFFFF; }
      .ro-bg-dark .ro-main-footer .ro-btn-bd-1:hover {
        background-color: #444444;
        border-color: #FFFFFF; }
    .ro-bg-dark .ro-main-footer .ro-footer-newletter input {
      border-color: #FFFFFF; }
    .ro-bg-dark .ro-main-footer .ro-footer-newletter a:hover {
      background-color: #AAAAAA; }
    .ro-bg-dark .ro-main-footer .ro-social {
      color: #666666; }
      .ro-bg-dark .ro-main-footer .ro-social li a:hover {
        color: #FFFFFF; }

.ro-foot-footer {
  text-align: center;
  border-top: 1px solid #AAAAAA;
  padding: 30px 0; }
  .ro-foot-footer h6 {
    margin-bottom: 10px;
    line-height: 1.6; }
  .ro-foot-footer.ro-index {
    padding: 20px 0;
    border: 0; }
  .ro-bg-dark .ro-foot-footer {
    color: #AAAAAA; }

@media (max-width: 1199px) {
  .ro-main-footer {
    text-align: center; }
    .ro-main-footer .ro-social {
      text-align: center; }
      .ro-main-footer .ro-social li {
        margin: 0 2px; } }

/* TEAM */
.ro-team-item {
  display: block;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  text-align: center;
  margin-bottom: 30px;
  max-width: 370px; }
  .ro-team-item .ro-content h5 {
    margin-bottom: 15px;
    margin-top: 25px; }
  .ro-team-item .ro-content p {
    margin-bottom: 10px;
    display: block;
    margin-right: auto;
    margin-left: auto; }
  .ro-team-item .ro-content .ro-social li {
    color: #666666;
    font-size: 18px; }

/* SERVICE */
.ro-service-1-wrapper {
  position: relative; }

.ro-service-1-item {
  position: relative;
  margin-bottom: 100px; }
  .ro-service-1-item .ro-image {
    position: relative;
    width: 60%;
    z-index: 2; }
  .ro-service-1-item .ro-content {
    position: relative;
    width: 50%;
    z-index: 3; }
    .ro-service-1-item .ro-content p {
      text-align: center;
      margin-bottom: 0;
      padding: 30px 30px 50px; }
      .ro-service-1-item .ro-content p .ro-more {
        position: absolute;
        right: 30px;
        bottom: 10px; }
  .ro-service-1-item > :first-child {
    float: left;
    margin-right: -10%; }
  .ro-service-1-item > :nth-child(2) {
    float: right; }
    .ro-service-1-item > :nth-child(2) img {
      float: right; }

@media (min-width: 1200px) {
  .ro-service-1-item.ro-service-1-v {
    padding-top: 150px; }
    .ro-service-1-item.ro-service-1-v .ro-image {
      position: relative;
      z-index: 2;
      margin-bottom: -70px;
      width: 85%; }
    .ro-service-1-item.ro-service-1-v .ro-content {
      position: relative;
      width: 100%;
      z-index: 3; }
      .ro-service-1-item.ro-service-1-v .ro-content h3, .ro-service-1-item.ro-service-1-v .ro-content p {
        float: left; }
      .ro-service-1-item.ro-service-1-v .ro-content h3 {
        width: 30%;
        padding-top: 80px; }
      .ro-service-1-item.ro-service-1-v .ro-content p {
        width: 70%;
        text-align: center;
        margin-bottom: 0;
        padding: 30px; } }

@media (max-width: 767px) {
  .ro-service-1-item {
    margin-bottom: 50px; }
    .ro-service-1-item .ro-image {
      position: relative;
      width: 80%;
      z-index: 2; }
    .ro-service-1-item .ro-content {
      position: relative;
      width: 70%;
      z-index: 3; }
      .ro-service-1-item .ro-content p {
        text-align: center;
        margin-bottom: 0;
        padding: 30px; }
    .ro-service-1-item > :first-child {
      margin-right: -50%; } }

@media (max-width: 479px) {
  .ro-service-1-item .ro-image {
    display: none; }
  .ro-service-1-item .ro-content {
    width: 100%; }
  .ro-service-1-item > :first-child {
    float: left;
    margin-right: -100%; } }

.ro-service-item-2 {
  display: block;
  margin-right: auto;
  margin-left: auto;
  max-width: 370px;
  text-align: center;
  margin-bottom: 25px; }
  .ro-service-item-2 img {
    margin-bottom: 30px; }
  .ro-service-item-2 h4 {
    margin-bottom: 15px; }
    .ro-service-item-2 h4:after {
      display: block;
      margin-right: auto;
      margin-left: auto;
      transition: all 0.4s;
      margin-top: 10px;
      content: " ";
      height: 0;
      width: 0;
      border-top: 1px solid #444444; }
  a:hover .ro-service-item-2 h4:after {
    width: 30px; }

.ro-service-item-3 {
  transition: all 0.4s;
  display: block;
  margin-right: auto;
  margin-left: auto;
  max-width: 370px;
  margin-top: 10px;
  margin-bottom: 70px;
  text-align: center; }
  .ro-service-item-3 h3 {
    transition: all 0.4s;
    font-weight: 600;
    margin-top: 40px;
    color: #FFFFFF; }
    .ro-service-item-3 h3:after {
      display: block;
      margin-right: auto;
      margin-left: auto;
      transition: all 0.4s;
      margin-top: 10px;
      content: " ";
      height: 0;
      width: 0;
      border-top: 1px solid #FFFFFF; }
  .ro-service-item-3 p {
    transition: all 0.4s;
    margin-bottom: 10px;
    padding: 15px;
    background-color: rgba(255, 255, 255, 0.6); }
  a:hover .ro-service-item-3 {
    margin-top: 0;
    margin-bottom: 60px; }
    a:hover .ro-service-item-3 h3 {
      margin-bottom: 35px; }
      a:hover .ro-service-item-3 h3:after {
        width: 30px; }
    a:hover .ro-service-item-3 p {
      margin-bottom: 20px; }

.ro-service-fullwidth {
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: 0 0;
  padding-bottom: 40px; }
  .ro-service-fullwidth.ro-bg-right {
    background-position: 100% 0; }
  .ro-service-fullwidth h3 {
    margin-bottom: 50px;
    margin-top: 60px; }

.ro-service-list ul {
  padding: 40px 90px 40px 40px; }
  .ro-service-list ul li {
    position: relative;
    line-height: 40px;
    font-style: italic; }
    .ro-service-list ul li:before, .ro-service-list ul li:after {
      content: " ";
      display: table; }
    .ro-service-list ul li:after {
      clear: both; }
  .ro-service-list ul .ro-service {
    position: relative;
    float: left;
    padding-right: 30px; }
  .ro-service-list ul .ro-separator {
    position: relative;
    display: table-cell;
    content: " ";
    width: 2000px;
    height: 28px;
    border-bottom: 1px solid #FFFFFF; }
  .ro-service-list ul .ro-price {
    text-align: right;
    position: absolute;
    font-weight: 700;
    width: 50px;
    right: -50px;
    top: 0; }

@media (min-width: 480px) and (max-width: 991px) {
  .ro-service-fullwidth, .ro-service-fullwidth.ro-bg-right {
    background-position: 50% 0; } }

@media (max-width: 479px) {
  .ro-service-list ul {
    padding: 15px 55px 15px 15px; }
    .ro-service-list ul .ro-service {
      padding-right: 0; }
    .ro-service-list ul .ro-price {
      width: 40px;
      right: -40px; }
    .ro-service-list ul .ro-separator {
      display: none; } }

.ro-specialize {
  padding-top: 60px;
  padding-bottom: 60px; }
  .ro-specialize img {
    display: inline-block;
    margin-bottom: 30px; }
  .ro-specialize h3 {
    margin-bottom: 35px; }
  .ro-specialize .ro-specialize-content {
    background-color: rgba(255, 255, 255, 0.8);
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 60px;
    padding-right: 40px; }
    .ro-specialize .ro-specialize-content li {
      list-style: disc;
      padding: 10px 0; }

@media (max-width: 479px) {
  .ro-specialize .ro-specialize-content {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 35px;
    padding-right: 15px; } }

.ro-service-accordion .ro-service-list ul {
  padding-top: 10px;
  padding-bottom: 10px; }
.ro-service-accordion .panel {
  border: 0;
  margin-bottom: 30px; }
.ro-service-accordion .panel-heading {
  padding: 0;
  border: 1px solid #FFFFFF; }
  .ro-service-accordion .panel-heading a {
    padding: 8px 30px;
    display: block;
    background-color: #c9e9ec; }
    .ro-service-accordion .panel-heading a:before {
      position: relative;
      left: -20px;
      content: "\e177";
      font-family: "aqua icon" !important;
      display: inline-block;
      font-size: 24px;
      vertical-align: -20%; }
    .ro-service-accordion .panel-heading a[aria-expanded='true'] {
      background-color: #FFFFFF; }
      .ro-service-accordion .panel-heading a[aria-expanded='true']:before {
        content: "\e170"; }
.ro-service-accordion .panel-body {
  border-top: 0 !important;
  background-color: #c9e9ec;
  height: 200px;
  overflow: auto; }

/* MEMBERSHIP */
.ro-membership-item {
  display: block;
  margin-right: auto;
  margin-left: auto;
  max-width: 270px;
  padding-top: 15px;
  padding-bottom: 50px;
  margin-bottom: 30px;
  text-align: center;
  border: 1px solid #AAAAAA; }
  .ro-membership-item .ro-price {
    position: relative;
    width: 100%; }
    .ro-membership-item .ro-price img {
      width: 100%; }
    .ro-membership-item .ro-price .ro-overlay {
      transition: all 0.4s;
      padding: 10px;
      opacity: 0;
      transform: scale(0.1); }
      .ro-membership-item .ro-price .ro-overlay .ro-cell-vertical-wrapper {
        background-color: rgba(255, 255, 255, 0.8); }
    .ro-membership-item .ro-price h1 {
      margin-bottom: 12px; }
    .ro-membership-item .ro-price .ro-small {
      font-size: 50%; }
  .ro-membership-item .ro-option {
    padding: 30px;
    padding-top: 40px; }
    .ro-membership-item .ro-option li:not(:first-child) {
      line-height: 50px;
      border-top: 1px solid #AAAAAA; }
  .ro-membership-item:hover .ro-price .ro-overlay {
    opacity: 1;
    transform: scale(1); }
  .ro-membership-item:hover > .ro-btn-bd-1 {
    background-color: #84C340;
    color: #FFFFFF;
    border-color: #84C340; }

@media (min-width: 768px) and (max-width: 991px) {
  .ro-membership-wrapper > div:nth-child(2n) .ro-membership-item {
    margin-left: 0; }
  .ro-membership-wrapper > div:nth-child(2n+1) .ro-membership-item {
    margin-right: 0; } }

/* TESTIMONIAL */
.ro-testimonial-slider .ro-item {
  text-align: center; }
  .ro-testimonial-slider .ro-item h5 {
    margin-bottom: 20px; }
.ro-testimonial-slider .flex-direction-nav {
  position: relative;
  text-align: center; }
  .ro-testimonial-slider .flex-direction-nav:before, .ro-testimonial-slider .flex-direction-nav:after {
    content: " ";
    display: table; }
  .ro-testimonial-slider .flex-direction-nav:after {
    clear: both; }
  .ro-testimonial-slider .flex-direction-nav .flex-nav-next, .ro-testimonial-slider .flex-direction-nav .flex-nav-prev {
    display: inline-block;
    margin: 0 15px; }
  .ro-testimonial-slider .flex-direction-nav .flex-next, .ro-testimonial-slider .flex-direction-nav .flex-prev {
    font-size: 0; }
    .ro-testimonial-slider .flex-direction-nav .flex-next:before, .ro-testimonial-slider .flex-direction-nav .flex-prev:before {
      font-size: 16px;
      font-family: "aqua icon" !important; }
  .ro-testimonial-slider .flex-direction-nav .flex-next:before {
    content: "d"; }
  .ro-testimonial-slider .flex-direction-nav .flex-prev:before {
    content: "b"; }

.ro-testimonial-scroll-wrapper {
  position: relative;
  float: left; }
  .ro-testimonial-scroll-wrapper:before {
    content: " ";
    z-index: 512;
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    height: 80px;
    width: 100%;
    background: linear-gradient(to bottom, #f3f3f0 0%, rgba(243, 243, 240, 0.99) 1%, rgba(243, 243, 240, 0.5) 50%, rgba(243, 243, 240, 0) 100%); }
  .ro-testimonial-scroll-wrapper:after {
    content: " ";
    z-index: 512;
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;
    height: 80px;
    width: 100%;
    background: linear-gradient(to bottom, rgba(243, 243, 240, 0) 0%, rgba(243, 243, 240, 0.5) 50%, rgba(243, 243, 240, 0.99) 99%, #f3f3f0 100%); }

.ro-testimonial-scroll {
  position: relative;
  float: left;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.5);
  height: 460px;
  overflow: auto; }
  .ro-testimonial-scroll .ro-hr {
    border-color: #AAAAAA;
    margin: 0 auto;
    width: 75%; }
  .ro-testimonial-scroll .ro-item {
    padding: 50px 40px 20px; }

.ro-testimonial-scroll-image {
  height: 460px;
  padding-top: 60px; }

/* HOME */
.ro-home-slider-1-background {
  width: 1520px;
  height: 620px; }
  .ro-home-slider-1-background:before, .ro-home-slider-1-background:after {
    width: 1500px;
    height: 600px;
    content: " ";
    position: absolute; }
  .ro-home-slider-1-background.ro-behind-bg-1:before {
    background-color: #C6D9C6; }
  .ro-home-slider-1-background.ro-front-bg-1:after {
    background-color: #C6D9C6; }
  .ro-home-slider-1-background.ro-behind-bg-2:before {
    background-color: #D9D8C1; }
  .ro-home-slider-1-background.ro-front-bg-2:after {
    background-color: #D9D8C1; }
  .ro-home-slider-1-background.ro-behind-bg-3:before {
    background-color: #DEB6B6; }
  .ro-home-slider-1-background.ro-front-bg-3:after {
    background-color: #DEB6B6; }
  .ro-home-slider-1-background.ro-behind-bg-4:before {
    background-color: #ABDCE1; }
  .ro-home-slider-1-background.ro-front-bg-4:after {
    background-color: #ABDCE1; }
  .ro-home-slider-1-background.ro-behind-bg-5:before {
    background-color: #E7E6E1; }
  .ro-home-slider-1-background.ro-front-bg-5:after {
    background-color: #E7E6E1; }
  .ro-home-slider-1-background:before {
    bottom: 0;
    right: 0; }
  .ro-home-slider-1-background:after {
    top: 0;
    left: 0; }

.ro-slider-style-2-caption {
  position: absolute;
  color: #FFFFFF;
  border-bottom: 1px solid #FFFFFF;
  height: 25px;
  width: 300px;
  font-style: italic;
  font-weight: 400; }

.ro-slider-style-2-list {
  font-size: 18px; }
  .ro-slider-style-2-list:before {
    content: "•";
    font-size: 24px;
    margin: 0 10px; }

#ro-slider-style2-background {
  transition: all 0.4s; }

.ro-home-fullheight-container {
  padding-top: 120px; }

.ro-onepage-home {
  height: 600px;
  background-image: url("../images/home-onepage.jpg");
  background-size: cover; }
  .ro-onepage-home .ro-content {
    line-height: 1.2; }
  .ro-onepage-home .ro-aqua-brand {
    position: relative;
    margin-left: 80px;
    top: -10px; }

.ro-fullwidth-home {
  height: 600px;
  background-image: url("../images/home-fullwidth.jpg");
  background-size: cover; }
  .ro-fullwidth-home .ro-content {
    line-height: 1.2; }
    .ro-fullwidth-home .ro-content p {
      color: #FFFFFF; }
    .ro-fullwidth-home .ro-content i {
      font-weight: 400; }
  .ro-fullwidth-home .ro-aqua-brand {
    position: relative;
    margin-left: 80px;
    top: -10px; }

@media (min-width: 768px) {
  .ro-home-fullheight-container {
    display: table;
    height: 100vh;
    width: 100%; }
    .ro-home-fullheight-container .ro-home-slider-2-wrapper {
      display: table-cell;
      vertical-align: bottom; } }

@media (min-width: 768px) and (max-width: 991px) {
  .ro-home-fullheight-container {
    padding-top: 220px; } }

@media (max-width: 767px) {
  .ro-onepage-home, .ro-fullwidth-home {
    text-align: center;
    height: 400px; }
    .ro-onepage-home .ro-aqua-brand, .ro-fullwidth-home .ro-aqua-brand {
      position: relative;
      display: block;
      margin-left: 0;
      top: 15px; }
  .ro-onepage-home {
    position: relative;
    z-index: 1; }
    .ro-onepage-home:after {
      content: " ";
      position: absolute;
      display: block;
      background: rgba(231, 230, 225, 0.6);
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1; }
  .ro-fullwidth-home {
    position: relative;
    z-index: 1; }
    .ro-fullwidth-home:after {
      content: " ";
      position: absolute;
      display: block;
      background: rgba(222, 182, 182, 0.6);
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1; } }

/* ABOUT */
.ro-history-img {
  margin: 25px auto 60px; }

/* SHOP */
.ro-rating span:not(.ro-rated) {
  margin: 2px;
  color: #FFFFFF;
  text-shadow: 0px 0px 2px #444444; }
.ro-rating .ro-rated {
  margin: 2px;
  color: #444444; }

.ro-shop-item {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 370px;
  margin-bottom: 25px; }
  .ro-shop-item img {
    width: 100%; }
  .ro-shop-item .ro-overlay {
    padding: 20px;
    opacity: 0;
    transition: all 0.4s; }
  .ro-shop-item:hover .ro-overlay {
    opacity: 1; }

.ro-shop-overlay {
  background-color: rgba(255, 255, 255, 0.7);
  border: 1px solid #AAAAAA;
  text-transform: uppercase;
  overflow: hidden; }
  .ro-shop-overlay h4 {
    letter-spacing: 3px; }
  .ro-shop-overlay h2 {
    letter-spacing: 5px; }
  .ro-shop-overlay .ro-btn-bd-2 {
    padding-left: 25px;
    padding-right: 25px; }

@media (min-width: 480px) {
  .ro-shop-item-name-1 {
    margin-top: 75px; }
  .ro-shop-item-price-1 {
    margin-top: 16px;
    margin-bottom: 60px; } }

@media (max-width: 479px) {
  .ro-shop-item-name-1 {
    margin-top: 50px; }
  .ro-shop-item-price-1 {
    margin-top: 16px;
    margin-bottom: 60px; } }

.ro-shop-header img {
  display: block;
  margin-right: auto;
  margin-left: auto; }

.ro-shop-header-right {
  display: block;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  max-width: 360px;
  line-height: 1.2; }
  .ro-shop-header-right .ro-cell-bottom {
    padding-bottom: 20px; }
  .ro-shop-header-right h3.ro-hr-heading {
    margin-bottom: 15px; }

.ro-shop-tab-controler {
  text-align: center;
  position: relative;
  font-family: "aqua" , sans-serif;
  font-weight: 400;
  font-size: 18px; }
  .ro-shop-tab-controler:before {
    content: " ";
    position: absolute;
    display: block;
    top: 50%;
    width: 100%;
    height: 0;
    border-top: 1px solid #AAAAAA;
    z-index: -1; }
  .ro-shop-tab-controler li {
    display: inline-block;
    padding: 5px 30px;
    margin: 0 5%;
    background-color: #FFFFFF;
    line-height: 50px; }
    .ro-shop-tab-controler li a {
      transition: color 0.4s; }
      .ro-shop-tab-controler li a:hover {
        color: #84C340; }
    .ro-shop-tab-controler li.active {
      font-size: 24px; }

.ro-shop-tab-item {
  display: block;
  margin-right: auto;
  margin-left: auto;
  max-width: 300px;
  text-align: center;
  position: relative;
  padding-top: 40px;
  margin-bottom: 30px; }
  .ro-shop-tab-item .ro-image {
    position: relative;
    margin-bottom: 30px; }
    .ro-shop-tab-item .ro-image img {
      width: 100%; }
  .ro-shop-tab-item .ro-selloff {
    transition: all 0.4s;
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 10px;
    line-height: 1;
    font-weight: 700;
    font-size: 18px;
    background-color: rgba(255, 255, 255, 0.7); }
  .ro-shop-tab-item .ro-overlay {
    transition: all 0.4s;
    padding: 20px;
    opacity: 0; }
    .ro-shop-tab-item .ro-overlay .ro-overlay-inner {
      width: 100%;
      height: 100%;
      position: relative;
      background-color: rgba(255, 255, 255, 0.7); }
      .ro-shop-tab-item .ro-overlay .ro-overlay-inner a {
        display: inline-block;
        position: relative;
        width: 45px;
        height: 45px;
        border: 1px solid #444444;
        line-height: 43px;
        border-radius: 23px;
        font-size: 16px; }
        .ro-shop-tab-item .ro-overlay .ro-overlay-inner a:hover {
          color: #FFFFFF;
          background-color: #444444; }
        .ro-shop-tab-item .ro-overlay .ro-overlay-inner a:first-child {
          top: -25px;
          left: -5px; }
        .ro-shop-tab-item .ro-overlay .ro-overlay-inner a:last-child {
          top: 25px;
          left: 5px; }
      .ro-shop-tab-item .ro-overlay .ro-overlay-inner:before {
        content: ' ';
        position: absolute;
        height: 60px;
        width: 1px;
        display: block;
        background-color: #AAAAAA;
        top: 50%;
        left: 50%;
        transform: translateY(-30px) rotate(45deg); }
  .ro-shop-tab-item .ro-content h5 {
    margin-bottom: 20px; }
  .ro-shop-tab-item .ro-content h3 {
    margin-bottom: 15px; }
    .ro-shop-tab-item .ro-content h3 del {
      color: #AAAAAA;
      margin-right: 20px; }
  .ro-shop-tab-item:hover .ro-selloff {
    opacity: 0; }
  .ro-shop-tab-item:hover .ro-overlay {
    opacity: 1; }

.ro-shoptab-separator {
  width: 100%;
  height: 0;
  border-top: 1px solid #EEEEEE; }

.pagination {
  display: inline-block; }
  .pagination li > a {
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 26px;
    font-size: 16px;
    border: 1px solid #AAAAAA;
    color: #444444;
    margin: 3px; }
    .pagination li > a:hover {
      color: #FFFFFF;
      border: #84C340;
      background-color: #84C340; }
  .pagination li:first-child a, .pagination li:last-child a {
    font-size: 24px;
    width: 50px; }

@media (max-width: 767px) {
  .ro-shop-tab-controler li {
    margin: 5px; } }

@media (max-width: 479px) {
  .ro-shop-tab-controler li {
    margin: 0;
    width: 100%; } }

/* SPACE */
.ro-space-img {
  display: block;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 270px;
  margin-bottom: 30px; }
  .ro-space-img img {
    width: 100%; }
  .ro-space-img .ro-overlay {
    transition: all 0.4s;
    padding: 20px;
    opacity: 0; }
  .ro-space-img:hover .ro-overlay {
    opacity: 1; }

.ro-space-overlay {
  text-transform: uppercase; }
  .ro-space-overlay h5 {
    margin: 0;
    letter-spacing: 3px; }

@media (min-width: 1200px) {
  .ro-space-overlay-lg-1 {
    background-color: rgba(255, 255, 255, 0.7); }
  .ro-space-overlay-lg-2 {
    background-color: rgba(198, 217, 198, 0.7); } }

@media (min-width: 768px) and (max-width: 991px) {
  .ro-space-items > div:nth-child(2n) .ro-space-img {
    float: left; }
  .ro-space-items > div:nth-child(2n+1) .ro-space-img {
    float: right; }
  .ro-space-overlay-sm-1 {
    background-color: rgba(255, 255, 255, 0.7); }
  .ro-space-overlay-sm-2 {
    background-color: rgba(198, 217, 198, 0.7); } }

/* ADVERTISE */
.ro-advertise-1-content {
  text-align: center;
  display: block;
  padding: 10px;
  border: 1px solid #AAAAAA; }
  .ro-advertise-1-content .ro-advertise-1-text {
    position: relative;
    z-index: 1;
    background: url("../images/banner.jpg") no-repeat center center;
    background-size: cover;
    color: #444444;
    padding: 30px 0 20px; }
    .ro-advertise-1-content .ro-advertise-1-text:after {
      content: " ";
      position: absolute;
      display: block;
      background: rgba(255, 255, 255, 0.6);
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1; }
    .ro-advertise-1-content .ro-advertise-1-text h1 {
      margin: 0;
      font-size: 46px;
      font-weight: 600; }
    .ro-advertise-1-content .ro-advertise-1-text p {
      margin: 0;
      line-height: 20px;
      height: 20px; }

.ro-advertise-2 {
  background-color: #DEB6B6; }
  .ro-advertise-2 .ro-advertise-2-content p {
    font-weight: 400;
    color: #FFFFFF;
    letter-spacing: 1px;
    margin-top: 0;
    margin-bottom: 50px;
    line-height: 1.2;
    font-size: 24px; }
  .ro-advertise-2 .ro-advertise-2-content .ro-btn-bd-2 {
    border: 1px solid #DEB6B6; }

@media (min-width: 992px) {
  .ro-advertise-2-content {
    padding-top: 150px; } }

@media (max-width: 991px) {
  .ro-advertise-2-content {
    padding-top: 78px; } }

@media (max-width: 767px) {
  .ro-advertise-2-content {
    text-align: center;
    padding-top: 50px;
    padding-bottom: 50px; }
    .ro-advertise-2-content h3 {
      margin-bottom: 30px; } }

/* BLOG */
.ro-author-name {
  font-style: italic;
  font-size: 18px;
  color: #84C340;
  text-align: right; }

.ro-blog-article .ro-title {
  font-weight: 700; }
.ro-blog-article .ro-detail span {
  display: inline-block; }
  .ro-blog-article .ro-detail span a {
    color: #84C340; }
    .ro-blog-article .ro-detail span a:hover {
      color: #AAAAAA; }
.ro-blog-article .ro-image {
  position: relative;
  display: block; }
  .ro-blog-article .ro-image .ro-note {
    position: absolute;
    top: 35px;
    left: 35px;
    background-color: rgba(255, 255, 255, 0.7);
    padding: 15px;
    width: 242px;
    border: 1px solid #AAAAAA;
    overflow: hidden; }
    .ro-blog-article .ro-image .ro-note .ro-note-top, .ro-blog-article .ro-image .ro-note .ro-note-bottom {
      position: absolute;
      right: 15px;
      font-size: 18px;
      line-height: 18px; }
    .ro-blog-article .ro-image .ro-note .ro-note-top {
      top: 15px; }
    .ro-blog-article .ro-image .ro-note .ro-note-bottom {
      bottom: 15px; }
    .ro-blog-article .ro-image .ro-note .ro-note-content {
      font-style: italic;
      margin: 20px 12px;
      line-height: 25px; }
      .ro-blog-article .ro-image .ro-note .ro-note-content span {
        font-size: 16px; }
.ro-blog-article .ro-content .ro-author {
  color: #84C340;
  text-align: right;
  font-style: italic;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 30px; }
.ro-blog-article .ro-tag {
  font-style: italic;
  font-weight: 400;
  margin-bottom: 15px;
  color: #84C340; }
  .ro-blog-article .ro-tag a:hover {
    color: #AAAAAA; }
.ro-blog-article .ro-social a:not(:first-child) {
  margin-left: 20px; }
.ro-blog-article .ro-social .ro-like, .ro-blog-article .ro-social .ro-subscribe {
  padding: 4px 22px 4px 22px;
  display: inline-block;
  font-size: 14px;
  outline: none;
  line-height: 18px;
  text-align: center;
  border-radius: 20px; }
.ro-blog-article .ro-social .ro-like {
  border: 1px solid #AAAAAA;
  background-color: #EEEEEE;
  color: #444444; }
  .ro-blog-article .ro-social .ro-like span {
    margin-left: 15px;
    margin-right: 9px;
    line-height: 14px;
    font-size: 20px;
    font-weight: 400; }
  .ro-blog-article .ro-social .ro-like:hover {
    border: 1px solid #84C340;
    background-color: #84C340;
    color: #FFFFFF; }
.ro-blog-article .ro-social .ro-subscribe {
  border: 1px solid #84C340;
  background-color: #84C340;
  color: #FFFFFF; }
  .ro-blog-article .ro-social .ro-subscribe:hover {
    border: 1px solid #AAAAAA;
    background-color: #EEEEEE;
    color: #444444; }
.ro-blog-article .ro-loading {
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  margin: 58px 0; }
@media (min-width: 768px) {
  .ro-blog-article .ro-title {
    font-size: 36px; }
  .ro-blog-article .ro-note .ro-note-content {
    font-size: 18px; } }
@media (max-width: 767px) {
  .ro-blog-article .ro-title {
    font-size: 28px; }
  .ro-blog-article .ro-note p {
    height: 6.4em; }
    .ro-blog-article .ro-note p .ro-note-content {
      font-size: 16px; }
  .ro-blog-article .ro-loading {
    margin: 45px 0; } }
@media (min-width: 480px) {
  .ro-blog-article .ro-detail span:not(:first-child) {
    margin-left: 55px; } }
@media (max-width: 479px) {
  .ro-blog-article .ro-image .ro-note {
    display: none; }
  .ro-blog-article .ro-detail span:not(:last-child) {
    margin-right: 55px; } }

.ro-blog-article.ro-preview {
  border-bottom: 1px solid #AAAAAA;
  padding-bottom: 70px;
  padding-top: 45px; }
  .ro-blog-article.ro-preview .ro-title {
    font-style: italic; }
  .ro-blog-article.ro-preview .ro-image {
    margin-top: 50px;
    margin-bottom: 50px; }
  .ro-blog-article.ro-preview .ro-content {
    overflow: hidden;
    margin-bottom: 20px; }
    .ro-blog-article.ro-preview .ro-content p {
      margin-bottom: 0; }
  @media (max-width: 767px) {
    .ro-blog-article.ro-preview .ro-content {
      height: 7.2em; } }
  @media (min-width: 651px) {
    .ro-blog-article.ro-preview .ro-detail {
      text-align: right; } }
  @media (max-width: 650px) {
    .ro-blog-article.ro-preview .ro-detail {
      text-align: left; } }

.ro-blog-article.ro-style-1 {
  padding-top: 45px; }
  .ro-blog-article.ro-style-1 .ro-title {
    font-style: italic;
    margin-bottom: 20px; }
  .ro-blog-article.ro-style-1 .ro-image {
    margin-top: 30px;
    margin-bottom: 50px; }
  .ro-blog-article.ro-style-1 .ro-content .ro-first-letter::first-letter {
    font-size: xx-large;
    font-weight: 700;
    font-style: italic;
    line-height: 20px; }
  .ro-blog-article.ro-style-1 .ro-related-1 {
    max-width: 200px;
    float: right; }
    .ro-blog-article.ro-style-1 .ro-related-1 p {
      margin-top: 30px; }
  @media (min-width: 651px) {
    .ro-blog-article.ro-style-1 .ro-detail {
      text-align: right; } }
  @media (max-width: 650px) {
    .ro-blog-article.ro-style-1 .ro-detail {
      text-align: left; } }

.ro-blog-article.ro-style-2 .ro-title {
  margin-top: 45px;
  margin-bottom: 25px; }
.ro-blog-article.ro-style-2 .ro-content {
  margin-top: 50px; }

.ro-blog-comment .ro-header {
  border-bottom: 1px solid #AAAAAA; }
  .ro-blog-comment .ro-header h3 {
    margin-top: 45px;
    margin-bottom: 25px; }
  .ro-blog-comment .ro-header .ro-btn {
    width: 33px;
    height: 33px;
    float: right;
    text-align: center;
    margin-top: 45px;
    transition: all 0.4s;
    border: 1px solid #AAAAAA;
    background-color: #EEEEEE;
    color: #444444; }
    .ro-blog-comment .ro-header .ro-btn i {
      display: block;
      font-size: 30px;
      line-height: 33px;
      color: #444444;
      transition: all 0.4s; }
    .ro-blog-comment .ro-header .ro-btn:hover {
      border: 1px solid #84C340;
      background-color: #84C340;
      color: #FFFFFF; }
      .ro-blog-comment .ro-header .ro-btn:hover i {
        color: #FFFFFF; }
.ro-blog-comment .ro-blog-comment-list {
  margin-bottom: 50px; }
  .ro-blog-comment .ro-blog-comment-list article {
    position: relative;
    padding: 48px 0 0 100px; }
    .ro-blog-comment .ro-blog-comment-list article footer h4 {
      font-style: italic;
      margin: 0 0 18px; }
    .ro-blog-comment .ro-blog-comment-list article footer .ro-profile {
      display: block;
      position: absolute;
      width: 70px;
      height: 70px;
      top: 48px;
      left: 0; }
    .ro-blog-comment .ro-blog-comment-list article .ro-comment p {
      margin: 0; }
    .ro-blog-comment .ro-blog-comment-list article .ro-footer {
      margin-bottom: 35px; }
      .ro-blog-comment .ro-blog-comment-list article .ro-footer p {
        margin: 0;
        font-size: 14px;
        font-style: italic;
        display: inline-block;
        color: #84C340; }
      .ro-blog-comment .ro-blog-comment-list article .ro-footer .ro-btn i {
        position: relative;
        top: 5px;
        display: inline-block;
        color: #666666;
        line-height: 20px;
        font-size: 30px;
        transition: all 0.4s; }
      .ro-blog-comment .ro-blog-comment-list article .ro-footer .ro-btn:hover i {
        color: #84C340; }
    .ro-blog-comment .ro-blog-comment-list article .ro-divide {
      width: 100%;
      height: 0;
      border-bottom: 1px solid #AAAAAA; }
  @media (min-width: 480px) {
    .ro-blog-comment .ro-blog-comment-list article .ro-footer {
      padding-right: 20px;
      text-align: right; }
      .ro-blog-comment .ro-blog-comment-list article .ro-footer span {
        float: right;
        margin-left: 5px; } }
  @media (max-width: 479px) {
    .ro-blog-comment .ro-blog-comment-list article .ro-footer {
      padding-right: 0;
      text-align: left; }
      .ro-blog-comment .ro-blog-comment-list article .ro-footer span {
        float: right;
        margin-left: 0px; } }

.ro-blog-contact-form {
  margin-bottom: 60px; }

.ro-blog-side-bar .ro-title {
  margin-bottom: 40px;
  background-color: #EEEEEE;
  border: 1px solid #AAAAAA;
  width: 100%;
  text-transform: uppercase;
  padding: 10px 0;
  text-align: center; }
  .ro-blog-side-bar .ro-title h5 {
    margin: 0;
    line-height: 18px; }
.ro-blog-side-bar .ro-title:not(:first-child) {
  margin-top: 40px; }
.ro-blog-side-bar .ro-news {
  margin-bottom: 20px;
  position: relative; }
  .ro-blog-side-bar .ro-news .ro-image {
    position: absolute;
    top: 8px;
    width: 90px;
    height: 55px;
    float: left;
    opacity: 1;
    transition: all 0.4s; }
    .ro-blog-side-bar .ro-news .ro-image:hover {
      opacity: 0.7; }
  .ro-blog-side-bar .ro-news .ro-content {
    margin-left: 100px; }
    .ro-blog-side-bar .ro-news .ro-content .ro-date {
      margin-top: 10px;
      color: #bdbdbd;
      font-style: italic;
      display: block;
      font-weight: 400; }
    .ro-blog-side-bar .ro-news .ro-content a {
      margin-top: 0;
      color: #444444;
      transition: all 0.3s ease-in-out; }
      .ro-blog-side-bar .ro-news .ro-content a:hover {
        color: #84C340; }
.ro-blog-side-bar .ro-contact {
  width: 100%; }
  .ro-blog-side-bar .ro-contact td, .ro-blog-side-bar .ro-contact th {
    padding-bottom: 10px; }

.ro-blog-related a {
  margin-bottom: 40px;
  display: block;
  text-align: center; }
  .ro-blog-related a img {
    max-width: 100%; }
.ro-blog-related a:hover {
  opacity: 0.7; }

.ro-blog-side-contact {
  width: 100%; }
  .ro-blog-side-contact td, .ro-blog-side-contact th {
    padding-bottom: 10px; }

.ro-blog-sidebar-service-list li {
  font-size: 18px;
  line-height: 2.5; }
  .ro-blog-sidebar-service-list li:before {
    content: "\e177";
    vertical-align: -5%;
    font-family: "aqua icon";
    margin-right: 10px; }

.ro-one-page-post {
  position: relative; }
  .ro-one-page-post.ro-style-2 .ro-one-page-post-content {
    background-color: #D9D8C1; }
    .ro-one-page-post.ro-style-2 .ro-one-page-post-content .ro-heading {
      text-align: right; }
  .ro-one-page-post .ro-image {
    position: relative; }
  .ro-one-page-post .ro-one-page-post-content {
    background-color: #ABDCE1; }
    .ro-one-page-post .ro-one-page-post-content .ro-heading {
      color: #FFFFFF;
      font-style: italic; }
      .ro-one-page-post .ro-one-page-post-content .ro-heading::after {
        margin-right: auto;
        margin-left: auto;
        content: " ";
        display: block;
        width: 50px;
        margin-top: 15px;
        height: 0;
        border-top: 2px solid #FFFFFF; }
      .ro-one-page-post .ro-one-page-post-content .ro-heading.ro-left::after {
        margin-left: 0; }
      .ro-one-page-post .ro-one-page-post-content .ro-heading.ro-right::after {
        margin-right: 0; }
    .ro-one-page-post .ro-one-page-post-content .ro-body {
      width: 100%;
      overflow: hidden; }
    .ro-one-page-post .ro-one-page-post-content .ro-footer {
      margin-bottom: 0;
      text-align: right;
      font-style: italic;
      font-weight: 400;
      font-size: 14px; }
      .ro-one-page-post .ro-one-page-post-content .ro-footer span {
        display: inline-block; }
        .ro-one-page-post .ro-one-page-post-content .ro-footer span:not(:first-child):before {
          content: "/"; }
  @media (min-width: 1800px) {
    .ro-one-page-post .ro-one-page-post-content {
      padding: 60px 40px 60px 60px; }
      .ro-one-page-post .ro-one-page-post-content .ro-heading {
        margin: 0 0 60px; }
      .ro-one-page-post .ro-one-page-post-content .ro-body {
        height: 16.2em; }
      .ro-one-page-post .ro-one-page-post-content .ro-footer {
        margin-top: 60px; } }
  @media (max-width: 1799px) {
    .ro-one-page-post .ro-one-page-post-content {
      padding: 50px 40px 50px 60px; }
      .ro-one-page-post .ro-one-page-post-content .ro-heading {
        margin: 0 0 30px; }
      .ro-one-page-post .ro-one-page-post-content .ro-body {
        height: 50%; }
      .ro-one-page-post .ro-one-page-post-content .ro-footer {
        margin-top: 30px; } }
  @media (min-width: 1200px) {
    .ro-one-page-post .ro-one-page-post-content .ro-heading {
      font-size: 36px; }
    .ro-one-page-post .ro-one-page-post-content .ro-footer span:not(:first-child):before {
      margin-left: 20px;
      margin-right: 20px; } }
  @media (max-width: 1199px) {
    .ro-one-page-post .ro-one-page-post-content {
      padding: 40px 40px 40px 50px; }
      .ro-one-page-post .ro-one-page-post-content .ro-heading {
        margin: 0 0 20px;
        font-size: 24px; }
      .ro-one-page-post .ro-one-page-post-content .ro-body {
        height: 7.2em; }
      .ro-one-page-post .ro-one-page-post-content .ro-footer {
        margin-top: 20px; }
        .ro-one-page-post .ro-one-page-post-content .ro-footer span:not(:first-child):before {
          margin-left: 15px;
          margin-right: 15px; } }
  @media (min-width: 992px) {
    .ro-one-page-post.ro-style-2 .ro-image {
      float: right; }
    .ro-one-page-post.ro-style-2 .ro-one-page-post-content {
      left: 0; }
      .ro-one-page-post.ro-style-2 .ro-one-page-post-content::before {
        content: " ";
        left: 100%;
        right: -25px;
        top: 60px;
        border-left: 25px solid #D9D8C1;
        border-right: 0; }
    .ro-one-page-post .ro-image {
      width: 50%; }
    .ro-one-page-post .ro-one-page-post-content {
      position: absolute;
      width: 50%;
      height: 100%;
      top: 0;
      right: 0; }
      .ro-one-page-post .ro-one-page-post-content::before {
        content: " ";
        width: 0;
        height: 0;
        left: -25px;
        top: 60px;
        position: absolute;
        border-right: 26px solid #ABDCE1;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent; } }
  @media (max-width: 991px) {
    .ro-one-page-post.ro-style-2 .ro-one-page-post-content::before {
      content: " ";
      left: auto;
      right: 60px;
      top: -25px;
      border-top: 0;
      border-bottom: 25px solid #D9D8C1; }
    .ro-one-page-post .ro-image {
      width: 100%; }
    .ro-one-page-post .ro-one-page-post-content {
      position: relative;
      padding: 40px 40px 40px 50px; }
      .ro-one-page-post .ro-one-page-post-content::before {
        content: " ";
        width: 0;
        height: 0;
        left: 60px;
        top: -25px;
        position: absolute;
        border-right: 20px solid transparent;
        border-left: 20px solid transparent;
        border-top: 0;
        border-bottom: 25px solid #ABDCE1; }
      .ro-one-page-post .ro-one-page-post-content .ro-heading {
        margin: 0 0 20px;
        font-size: 24px; }
      .ro-one-page-post .ro-one-page-post-content .ro-body {
        height: 5.4em; }
      .ro-one-page-post .ro-one-page-post-content .ro-footer {
        margin-top: 20px; }
        .ro-one-page-post .ro-one-page-post-content .ro-footer span:not(:first-child):before {
          margin-left: 15px;
          margin-right: 15px; } }
  @media (max-width: 479px) {
    .ro-one-page-post .ro-one-page-post-content .ro-heading {
      font-size: 18px; }
    .ro-one-page-post .ro-one-page-post-content .ro-footer {
      text-align: left; }
      .ro-one-page-post .ro-one-page-post-content .ro-footer span {
        margin-right: 20px; }
        .ro-one-page-post .ro-one-page-post-content .ro-footer span:not(:first-child):before {
          display: none; } }

/* OTHER PAGE */
.ro-underconstruction-wrapper {
  position: relative;
  z-index: 1;
  background-image: url("../images/reservation.jpg");
  background-size: cover;
  border: 1px solid #AAAAAA;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 100px; }
  .ro-underconstruction-wrapper:after {
    content: " ";
    position: absolute;
    display: block;
    background: rgba(255, 255, 255, 0.9);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1; }

.ro-countdown-clock.is-countdown .countdown-section {
  display: inline-block; }
  .ro-countdown-clock.is-countdown .countdown-section .countdown-amount, .ro-countdown-clock.is-countdown .countdown-section .countdown-period {
    display: block;
    margin: 10px;
    text-align: center;
    font-weight: 600; }
  .ro-countdown-clock.is-countdown .countdown-section .countdown-amount {
    width: 80px;
    height: 80px;
    background-color: #84C340;
    color: #FFFFFF;
    line-height: 74px;
    font-size: 45px; }
  .ro-countdown-clock.is-countdown .countdown-section .countdown-period {
    font-size: 18px; }

.ro-404-container {
  display: block;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  background-image: url("../images/404.jpg");
  background-size: cover;
  max-width: 1520px;
  min-height: 61vh;
  height: 400px; }
  .ro-404-container .ro-heading {
    font-size: 72px;
    margin-bottom: 0;
    line-height: 1.2; }

.ro-demo-select-header-container {
  position: relative;
  padding-top: 30px;
  height: 380px;
  margin-bottom: 100px;
  text-align: center;
  color: #FFFFFF; }
  .ro-demo-select-header-container .ro-brand {
    margin: 25px 0;
    width: 200px; }

.ro-demo-select-footer-container {
  padding-top: 100px;
  padding-bottom: 100px;
  background-image: url("../images/demoselect-footer.png");
  background-repeat: no-repeat;
  background-position: 100% 50%;
  background-size: auto 100%; }

.ro-demo-item {
  display: block;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  max-width: 360px;
  margin-bottom: 50px; }
  .ro-demo-item .ro-content {
    position: relative;
    background-color: #EEEEEE;
    border: 1px solid #AAAAAA;
    padding: 10px;
    margin-bottom: 20px; }
  .ro-demo-item img {
    width: 100%;
    transition: all 0.4s; }
  .ro-demo-item .ro-overlay {
    transition: all 0.4s;
    padding: 10px;
    opacity: 0; }
    .ro-demo-item .ro-overlay .ro-cell-vertical-wrapper {
      background-color: rgba(171, 220, 225, 0.7); }
    .ro-demo-item .ro-overlay.ro-comming {
      opacity: 1; }
      .ro-demo-item .ro-overlay.ro-comming .ro-cell-vertical-wrapper {
        background-color: transparent; }
  .ro-demo-item:hover .ro-overlay {
    opacity: 1; }

@media (max-width: 1199px) {
  .ro-demo-select-header-container {
    margin-bottom: 20px; } }

