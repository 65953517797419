////////////////////////////////////////SCREEN////////////////////////////////////

$screen-xlg: 1800px;
$screen-lg: 1200px;
$screen-md: 992px;
$screen-sm: 768px;
$screen-xs: 480px;

////////////////////////////////////////COLOR/////////////////////////////////////
$main-color: #84C340;
$light-color: #FFFFFF;
$dark-color: #444444;
$darkgray-color: #666666;
$gray-color: #AAAAAA;
$lightgray-color: #EEEEEE;

$bg-color-1: #C6D9C6;
$bg-color-2: #D9D8C1;
$bg-color-3: #DEB6B6;
$bg-color-4: #ABDCE1;
$bg-color-5: #E7E6E1;

///////////////////////////////////////ANIMATION////////////////////////////////////

$duration-time: 0.4s;
$duration-longtime: 0.7s;

///////////////////////////////////////TYPOGRAPHY////////////////////////////////////

$font-size-1: 42px;
$font-size-2: 36px;
$font-size-3: 24px;
$font-size-4: 18px;
$font-size-5: 16px;
$font-size-6: 14px;
$base-font-size: $font-size-5;
$base-line-height: 1.8;
$text-block-margin-bottom: 20px;
$heading-font-weight: 700;

$font-size-list: $font-size-1, $font-size-2, $font-size-3, $font-size-4, $font-size-5, $font-size-6;