.ro-space-img {
    @include center-block;
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 270px;
    margin-bottom: 30px;

    img {
        width: 100%;
    }

    .ro-overlay {
        @include easing-animation;
        padding: 20px;
        opacity: 0;
    }

    &:hover .ro-overlay {
        opacity: 1;
    }
}

.ro-space-overlay {
    text-transform: uppercase;

    h5 {
        margin: 0;
        letter-spacing: 3px;
    }
}

@include breakpoint-min ($screen-lg) {
    .ro-space-overlay-lg-1 {
        background-color: rgba($light-color, 0.7);
    }
    .ro-space-overlay-lg-2 {
        background-color: rgba($bg-color-1, 0.7);
    }
}
@include breakpoint-in($screen-sm, $screen-md) {
    .ro-space-items > div:nth-child(2n) .ro-space-img {
        float: left;
    }
    .ro-space-items > div:nth-child(2n+1) .ro-space-img  {
        float: right;
    }
    .ro-space-overlay-sm-1 {
        background-color: rgba($light-color, 0.7);
    }
    .ro-space-overlay-sm-2 {
        background-color: rgba($bg-color-1, 0.7);
    }
}