.ro-rating {
    span:not(.ro-rated) {
        margin: 2px;
        color: $light-color;
        text-shadow: 0px 0px 2px $dark-color;
    }

    .ro-rated {
        margin: 2px;
        color: $dark-color;
    }
}

.ro-shop-item {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 370px;
    margin-bottom: 25px;

    img {
        width: 100%;
    }

    .ro-overlay {
        padding: 20px;
        opacity: 0;
        transition: all 0.4s;
    }

    &:hover .ro-overlay {
        opacity: 1;
    }
}

.ro-shop-overlay {
    background-color: rgba($light-color, 0.7);
    border: 1px solid $gray-color;
    text-transform: uppercase;
    overflow:hidden;

    h4 {
        letter-spacing: 3px;
    }

    h2 {
        letter-spacing: 5px;
    }

    .ro-btn-bd-2 {
        padding-left: 25px;
        padding-right: 25px;
    }
}

@include breakpoint-min($screen-xs) {
    .ro-shop-item-name-1 {
        margin-top: 75px;
    }

    .ro-shop-item-price-1 {
        margin-top: 16px;
        margin-bottom: 60px;
    }
}


@include breakpoint-max($screen-xs) {
    .ro-shop-item-name-1 {
        margin-top: 50px;
    }

    .ro-shop-item-price-1 {
        margin-top: 16px;
        margin-bottom: 60px;
    }
}


.ro-shop-header {
    img {
        @include center-block;
    }
}

.ro-shop-header-right {
    @include center-block;
    position: relative;
    max-width: 360px;
    line-height: 1.2;
    .ro-cell-bottom{
        padding-bottom: 20px;
    }
    h3.ro-hr-heading {
        margin-bottom: 15px;
    }
}

.ro-shop-tab-controler {
    text-align: center;
    position: relative;
    font-family: "aqua" , sans-serif;
    font-weight: 400;
    font-size: $font-size-4;

    &:before {
        content: " ";
        position: absolute;
        display: block;
        top: 50%;
        width: 100%;
        height: 0;
        border-top: 1px solid $gray-color;
        z-index: -1;
    }

    li {
        display: inline-block;
        padding: 5px 30px;
        margin: 0 5%;
        background-color: $light-color;
        line-height: 50px;

        a {
            transition: color $duration-time;

            &:hover {
                color: $main-color;
            }
        }

        &.active {
            font-size: $font-size-3;
        }
    }
}

.ro-shop-tab-item {
    @include center-block;
    max-width: 300px;
    text-align: center;
    position: relative;
    padding-top: 40px;
    margin-bottom: 30px;

    .ro-image {
        position: relative;
        margin-bottom: 30px;

        img {
            width: 100%;
        }
    }

    .ro-selloff {
        @include easing-animation;
        position: absolute;
        top: 5px;
        right: 5px;
        padding: 10px;
        line-height: 1;
        font-weight: 700;
        font-size: $font-size-4;
        background-color: rgba($light-color, 0.7);
    }

    .ro-overlay {
        @include easing-animation;
        padding: 20px;
        opacity: 0;

        .ro-overlay-inner {
            width: 100%;
            height: 100%;
            position: relative;
            background-color: rgba($light-color, 0.7);

            a {
                display: inline-block;
                position: relative;
                @include square(45px);
                border: 1px solid $dark-color;
                line-height: 43px;
                border-radius: 23px;
                font-size: $font-size-5;

                &:hover {
                    color: $light-color;
                    background-color: $dark-color;
                }

                &:first-child {
                    top: -25px;
                    left: -5px;
                }

                &:last-child {
                    top: 25px;
                    left: 5px;
                }
            }

            &:before {
                content: ' ';
                position: absolute;
                height: 60px;
                width: 1px;
                display: block;
                background-color: $gray-color;
                top: 50%;
                left: 50%;
                transform: translateY(-30px) rotate(45deg);
            }
        }
    }

    .ro-content {
        h5 {
            margin-bottom: 20px;
        }

        h3 {
            margin-bottom: 15px;

            del {
                color: $gray-color;
                margin-right: 20px;
            }
        }
    }

    &:hover {
        .ro-selloff {
            opacity: 0;
        }

        .ro-overlay {
            opacity: 1;
        }
    }
}

.ro-shoptab-separator {
    width: 100%;
    height: 0;
    border-top: 1px solid $lightgray-color;
}

.pagination {
    display: inline-block;

    li > a {
        @include square(40px);
        text-align: center;
        line-height: 26px;
        font-size: $font-size-5;
        border: 1px solid $gray-color;
        color: $dark-color;
        margin: 3px;

        &:hover {
            color: $light-color;
            border: $main-color;
            background-color: $main-color;
        }
    }

    li:first-child a, li:last-child a {
        font-size: 24px;
        width: 50px;
    }
}

@include breakpoint-max($screen-sm) {
    .ro-shop-tab-controler li {
        margin: 5px;
    }
}


@include breakpoint-max($screen-xs) {
    .ro-shop-tab-controler li {
        margin: 0;
        width: 100%;
    }
}