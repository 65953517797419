.ro-advertise-1-content {
    text-align: center;
    display: block;
    padding: 10px;
    border: 1px solid $gray-color;
    .ro-advertise-1-text {
        @include layer-after-on-bg(rgba(255, 255, 255, 0.6));
        background: url("../images/banner.jpg") no-repeat center center;
        background-size: cover;
        color: $dark-color;
        padding: 30px 0 20px;
        h1 {
            margin: 0;
            font-size: 46px;
            font-weight: 600;
        }
        p {
            margin: 0;
            line-height: 20px;
            height: 20px;
        }
    }
}
.ro-advertise-2 {
    background-color: $bg-color-3;

    .ro-advertise-2-content {
        p {
            font-weight: 400;
            color: #FFFFFF;
            letter-spacing: 1px;
            margin-top: 0;
            margin-bottom: 50px;
            line-height: 1.2;
            font-size: $font-size-3;
        }
        .ro-btn-bd-2 {
            border: 1px solid $bg-color-3;
        }
    }
}
@include breakpoint-min($screen-md) {
    .ro-advertise-2-content {
        padding-top: 150px;
    }
}
@include breakpoint-max ($screen-md) {
    .ro-advertise-2-content {
        padding-top: 78px;
    }
}
@include breakpoint-max ($screen-sm) {
    .ro-advertise-2-content {
        text-align: center;
        padding-top: 50px;
        padding-bottom: 50px; 
         h3 {
            margin-bottom: 30px; 
        }
    }
}