.ro-social {
    li {
        display: inline-block;
        margin-right: 3px;
        margin-left: 3px;
        font-size: 16px;

        a:hover {
            color: $main-color;
        }
    }
}

